import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faArrowLeft,
  faBackward,
  faEdit,
  faFastBackward,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import {
  ADD_BROKER,
  ADD_BROKER_HEADQUARTERS,
  BROKERS,
  LOGIN,
  UPDATE_BROKER_DATA,
  UPDATE_BROKER_HEADQUARTERS_BY_ID
} from "../../../configs/constants";
import MyVerticallyCenteredModal from "../../popup/MyVerticallyCenteredModal";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  attemptGetHeadquartersList,
  toggleMenuTab
} from "../../../redux/actions/indexActions";
import Pagination from "react-js-pagination";
class HeadQuarters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "Headquarter",
      countPerPage: 10
    };
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteHeadQuarters = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/headquarter/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetHeadquartersList(this.props.language, this.state.countPerPage, 1);
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
    this.setState({ isShowEditBlock: false });
  };

  componentDidMount() {
    const { page } = this.state;
    const { language } = this.props;
    this.props.toggleMenuTab(1);
    this.props.attemptGetHeadquartersList(language, this.state.countPerPage, page);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetHeadquartersList(this.props.language, this.state.countPerPage, pageNumber);
    });
  }
  render() {
    const { headquartersList } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Payment Options</title>
        </Helmet>
        <div className="container ">
          <h3>Headquarters list</h3>
          <table className="table overflow-auto">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {headquartersList &&
                headquartersList.list.length &&
                headquartersList.list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{`${item.sysname}`}</td>
                      <td>
                        <Link to={UPDATE_BROKER_HEADQUARTERS_BY_ID(item.id)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {headquartersList && headquartersList.count > this.state.countPerPage ? (
            <div class="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={headquartersList.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}

          <div className="d-flex flex-wrap">
            <button
              className="btn btn-light mb-2 mr-2"
              onClick={() => {
                this.props.currentBrokerSysname
                  ? this.props.history.push(
                      UPDATE_BROKER_DATA(this.props.currentBrokerSysname)
                    )
                  : this.props.history.push(ADD_BROKER);
              }}
            >
              {/*<i className="fas fa-arrow-left"></i>*/}
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
            <button className="btn btn-light mb-2">
              <Link
                className="text-decoration-none"
                to={ADD_BROKER_HEADQUARTERS}
              >
                <FontAwesomeIcon icon={faAddressCard} /> Add new headquarter
              </Link>
            </button>
          </div>
          {/*<button className="btn btn-light">*/}
          {/*    <Link to={UPDATE_BROKER_DATA( this.props.match.params.id)}>*/}
          {/*        /!*<FontAwesomeIcon icon={faAddressCard} />*!/*/}
          {/*        Back to broker*/}
          {/*    </Link>*/}
          {/*</button>*/}
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteHeadQuarters(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    paymentsList: store.paymentsList,
    headquartersList: store.headquartersList,
    language: store.language,
    currentBrokerSysname: store.currentBrokerSysname
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetHeadquartersList: (lang, countPerPage, page) =>
      dispatch(attemptGetHeadquartersList(lang, countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeadQuarters));
