import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import {
  ADD_BROKER_ACCOUNT_TYPE_SYSNAME,
  ADMIN_BROKER,
  LOGIN,
  UPDATE_ACCOUNT_TYPES_BY_BROKER_ID,
  UPDATE_BROKER_DATA
} from "../../../configs/constants";
import MyVerticallyCenteredModal from "../../popup/MyVerticallyCenteredModal";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { toggleMenuTab } from "../../../redux/actions/indexActions";
class BrokerAccountTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "account type"
    };
  }

  attemptGetAccountTypes = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        `${ADMIN_BROKER}/account-type/${this.props.match.params.id}?lang=en`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({
          accountTypes: data
        });
      })
      .catch(err => console.log(err));
  };

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteAccountType = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/broker/account-type/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.attemptGetAccountTypes();
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  componentDidMount() {
    this.attemptGetAccountTypes();
    this.props.toggleMenuTab(1);
  }
  render() {
    const { accountTypes } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Account types</title>
        </Helmet>
        <div className="container ">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {accountTypes &&
                accountTypes.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{`${item.accountType}(${item.tradingPlatform})`}</td>
                      <td>
                        <Link
                          to={UPDATE_ACCOUNT_TYPES_BY_BROKER_ID(
                            item.id,
                            this.props.match.params.id
                          )}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button className="btn btn-light">
            <Link
              to={ADD_BROKER_ACCOUNT_TYPE_SYSNAME(
                this.props.currentBrokerSysname || this.props.match.params.id
              )}
            >
              <FontAwesomeIcon icon={faAddressCard} /> Add new account type
            </Link>
          </button>
          <button className="btn btn-light">
            <Link to={UPDATE_BROKER_DATA(this.props.match.params.id)}>
              {/*<FontAwesomeIcon icon={faAddressCard} />*/}
              Back to broker
            </Link>
          </button>
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteAccountType(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentBrokerId: store.currentBrokerId,
    currentBrokerSysname: store.currentBrokerSysname
  };
};
function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BrokerAccountTypes));
