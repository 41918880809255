import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import {
  attemptGetExpertList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { buttons } from "./../../configs/constants";
import { Helmet } from "react-helmet";
import { ARTICLES } from "../../configs/constants";

class AddArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: "",
      slug: "",
      en: {
        title: "",
        description: "",
        text: ""
      },
      ru: {
        title: "",
        description: "",
        text: ""
      },
      es: {
        title: "",
        description: "",
        text: ""
      },
      pt: {
        title: "",
        description: "",
        text: ""
      },
      ar: {
        title: "",
        description: "",
        text: ""
      },
      fa: {
        title: "",
        description: "",
        text: ""
      },
      tr: {
        title: "",
        description: "",
        text: ""
      },
      vi: {
        title: "",
        description: "",
        text: ""
      },
      ja: {
        title: "",
        description: "",
        text: ""
      },
      ID: {
        title: "",
        description: "",
        text: ""
      },
      cs: {
        title: "",
        description: "",
        text: ""
      },
      hi: {
        title: "",
        description: "",
        text: ""
      },
      de: {
        title: "",
        description: "",
        text: ""
      },
      it: {
        title: "",
        description: "",
        text: ""
      },
      ms: {
        title: "",
        description: "",
        text: ""
      },
      zh: {
        title: "",
        description: "",
        text: ""
      },
      zh_cn: {
        title: "",
        description: "",
        text: ""
      },

      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,

      item: "Article",
      expertId: null,

      openTransFields: true,

      langBtnIndex: 1,
      lang: "en"
    };
  }

  componentDidMount() {
    this.props.attemptGetExpertList(100, 1);
    this.props.toggleMenuTab(2);
  }

  close = () => {
    this.setState(
      {
        title: "",
        description: "",
        picture: "",
        slug: "",
        expertId: 3,
        open: false,
        isSuccessModalOpened: false,
        text: ""
      },
      () => this.props.history.push(ARTICLES)
    );
  };
  sendRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    const urlencoded = new URLSearchParams();
    urlencoded.append("slug", this.state.slug);
    urlencoded.append("expertId", this.state.expertId);
    urlencoded.append("translations[en][title]", this.state.en.title);
    urlencoded.append(
      "translations[en][description]",
      this.state.en.description
    );
    urlencoded.append("translations[en][text]", this.state.en.text);
    urlencoded.append("translations[ru][title]", this.state.ru.title);
    urlencoded.append(
      "translations[ru][description]",
      this.state.ru.description
    );
    urlencoded.append("translations[ru][text]", this.state.ru.text);

    ///////////////////////////////////es////////////////////////////////////////
    urlencoded.append("translations[es][title]", this.state.es.title);
    urlencoded.append(
      "translations[es][description]",
      this.state.es.description
    );
    urlencoded.append("translations[es][text]", this.state.es.text);
    ///////////////////////////////////pt////////////////////////////////////////
    urlencoded.append("translations[pt][title]", this.state.pt.title);
    urlencoded.append(
      "translations[pt][description]",
      this.state.pt.description
    );
    urlencoded.append("translations[pt][text]", this.state.pt.text);
    ///////////////////////////////////ar////////////////////////////////////////
    urlencoded.append("translations[ar][title]", this.state.ar.title);
    urlencoded.append(
      "translations[ar][description]",
      this.state.ar.description
    );
    urlencoded.append("translations[ar][text]", this.state.ar.text);
    ///////////////////////////////////fa////////////////////////////////////////
    urlencoded.append("translations[fa][title]", this.state.fa.title);
    urlencoded.append(
      "translations[fa][description]",
      this.state.fa.description
    );
    urlencoded.append("translations[fa][text]", this.state.fa.text);
    ///////////////////////////////////tr////////////////////////////////////////
    urlencoded.append("translations[tr][title]", this.state.tr.title);
    urlencoded.append(
      "translations[tr][description]",
      this.state.tr.description
    );
    urlencoded.append("translations[tr][text]", this.state.tr.text);
    ///////////////////////////////////vi////////////////////////////////////////
    urlencoded.append("translations[vi][title]", this.state.vi.title);
    urlencoded.append(
      "translations[vi][description]",
      this.state.vi.description
    );
    urlencoded.append("translations[vi][text]", this.state.vi.text);
    ///////////////////////////////////ja////////////////////////////////////////
    urlencoded.append("translations[ja][title]", this.state.ja.title);
    urlencoded.append(
      "translations[ja][description]",
      this.state.ja.description
    );
    urlencoded.append("translations[ja][text]", this.state.ja.text);
    ///////////////////////////////////ID////////////////////////////////////////
    urlencoded.append("translations[ID][title]", this.state.ID.title);
    urlencoded.append(
      "translations[ID][description]",
      this.state.ID.description
    );
    urlencoded.append("translations[ID][text]", this.state.ID.text);
    ///////////////////////////////////cs////////////////////////////////////////
    urlencoded.append("translations[cs][title]", this.state.cs.title);
    urlencoded.append(
      "translations[cs][description]",
      this.state.cs.description
    );
    urlencoded.append("translations[cs][text]", this.state.cs.text);
    ///////////////////////////////////hi////////////////////////////////////////
    urlencoded.append("translations[hi][title]", this.state.hi.title);
    urlencoded.append(
      "translations[hi][description]",
      this.state.hi.description
    );
    urlencoded.append("translations[hi][text]", this.state.hi.text);
    ///////////////////////////////////de////////////////////////////////////////
    urlencoded.append("translations[de][title]", this.state.de.title);
    urlencoded.append(
      "translations[de][description]",
      this.state.de.description
    );
    urlencoded.append("translations[de][text]", this.state.de.text);
    ///////////////////////////////////it////////////////////////////////////////
    urlencoded.append("translations[it][title]", this.state.it.title);
    urlencoded.append(
      "translations[it][description]",
      this.state.it.description
    );
    urlencoded.append("translations[it][text]", this.state.it.text);
    ///////////////////////////////////ms////////////////////////////////////////
    urlencoded.append("translations[ms][title]", this.state.ms.title);
    urlencoded.append(
      "translations[ms][description]",
      this.state.ms.description
    );
    urlencoded.append("translations[ms][text]", this.state.ms.text);
    ///////////////////////////////////zh////////////////////////////////////////
    urlencoded.append("translations[zh][title]", this.state.zh.title);
    urlencoded.append(
      "translations[zh][description]",
      this.state.zh.description
    );
    urlencoded.append("translations[zh][text]", this.state.zh.text);
    ///////////////////////////////////zh_cn////////////////////////////////////////
    urlencoded.append("translations[zh_cn][title]", this.state.zh_cn.title);
    urlencoded.append(
      "translations[zh_cn][description]",
      this.state.zh_cn.description
    );
    urlencoded.append("translations[zh_cn][text]", this.state.zh_cn.text);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_API_URL + "/admin/article", requestOptions)
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  render() {
    const { lang } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Articles | Create new article</title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              <div className={`${this.state.openTransFields ? "hide" : ""}`}>
                {buttons.map((item, index) => {
                  return (
                    <button
                      className={`btn ${
                        this.state.langBtnIndex === item.index
                          ? "activeBtn"
                          : "pasivBtn"
                      }`}
                      onClick={() => {
                        this.setState({
                          langBtnIndex: item.index,
                          lang: item.lang
                        });
                      }}
                    >
                      {item.lang}
                    </button>
                  );
                })}
                <div className="">
                  <h2>
                    <strong>Article title / </strong>
                    <span>
                      <input
                        className="p-1"
                        type="text"
                        placeholder="Article title"
                        value={this.state[lang].title}
                        onChange={e => {
                          this.setState({
                            [lang]: {
                              ...this.state[lang],
                              title: e.target.value
                            }
                          });
                        }}
                      />
                    </span>
                  </h2>
                  <div className="broker_txt_block">
                    <span>
                      <textarea
                        name="desc"
                        id=""
                        cols="150"
                        rows="3"
                        placeholder="Broker short description"
                        value={this.state[lang].description}
                        onChange={e => {
                          this.setState({
                            [lang]: {
                              ...this.state[lang],
                              description: e.target.value
                            }
                          });
                        }}
                      />
                    </span>
                  </div>
                  <div className="broker_txt_block">
                    <span>
                      <textarea
                        name="desc"
                        id=""
                        cols="150"
                        rows="3"
                        placeholder="Broker text"
                        value={this.state[lang].text}
                        onChange={e => {
                          this.setState({
                            [lang]: {
                              ...this.state[lang],
                              text: e.target.value
                            }
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 rating_info">
              <div className="d-flex justify-content-between mb-2">
                <span>Slug</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state.slug}
                    onChange={e => {
                      this.setState({ slug: e.target.value });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <span>Expert</span>
                <span className="d-flex">
                  <select
                    className="custom-select"
                    onChange={e => this.setState({ expertId: e.target.value })}
                  >
                    <option></option>
                    {this.props.experts &&
                      this.props.experts.list &&
                      this.props.experts.list.map((item, index) => {
                        return (
                          <option
                            value={item.expertId}
                            onChange={() =>
                              this.setState({ expertId: item.expertId })
                            }
                          >
                            {this.state.expertId === item.expertId
                              ? null
                              : item.lastName}
                          </option>
                        );
                      })}
                  </select>
                </span>
              </div>
            </div>

            <div className="col-12">
              {this.state.langBtnIndex && this.state.expertId ? (
                <button
                  className="btn btn-primary mb-4"
                  disabled={!this.state.slug || !this.state.openTransFields}
                  onClick={() => this.setState({ openTransFields: false })}
                >
                  NEXT-->
                </button>
              ) : null}
            </div>

            <div className="col-12">
              <Button
                variant="primary"
                size="lg"
                disabled={!this.state.slug || !this.state.expertId}
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                ADD ARTICLE
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    experts: store.experts
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetExpertList: (countPerPage, page) =>
      dispatch(attemptGetExpertList(countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddArticle));
