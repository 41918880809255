import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { ADD_EXPERT, EXPERT_UPDATE_PAGE, LOGIN } from "../../configs/constants";
import {
  attemptGetExpertList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import Pagination from "react-js-pagination";
import "./../../assets/css/pagination.css";
import { Helmet } from "react-helmet";

class Experts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "expert",
      countPerPage: 10
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetExpertList(this.state.countPerPage, pageNumber);
    });
  }

  componentDidMount() {
    this.props.attemptGetExpertList(this.state.countPerPage, this.state.activePage);
    this.props.toggleMenuTab(4);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.countPerPage !== prevState.countPerPage) {
      this.props.attemptGetExpertList();
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteExpert = expertId => {
    fetch(process.env.REACT_APP_API_URL + `/admin/expert/` + expertId, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetExpertList(this.state.countPerPage, this.state.page);
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  render() {
    const { experts } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Experts</title>
        </Helmet>
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {experts &&
                experts.list &&
                experts.list.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>
                        <Link to={EXPERT_UPDATE_PAGE(item.expertId)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        {/*<div onClick={() => this.deleteExpert(item.expertId)}>*/}
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.expertId })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {experts && experts.count > this.state.countPerPage ? (
            <div className="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={experts.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}
          <button className="btn btn-light mb-4">
            <Link className={"text-decoration-none"} to={ADD_EXPERT}>
              <FontAwesomeIcon icon={faAddressCard} /> Add new expert
            </Link>
          </button>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteExpert(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    experts: store.experts,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetExpertList: (countPerPage, page) =>
      dispatch(attemptGetExpertList(countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Experts));
