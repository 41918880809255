import React from "react";
import "./../../assets/css/head.css";
import { Link, withRouter } from "react-router-dom";
import { LOGIN } from "../../configs/constants";
import {
  isUserLoggedIn,
  isUserLoggedOut,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import { cookies } from "../Login";
import AdminAvatar from "../AdminAvatar";

class MainPage extends React.Component {
  attemptLogOut = () => {
    cookies.remove("refreshToken");
    localStorage.removeItem("accessToken");
    this.props.userLogOut();
    this.props.history.push(LOGIN);
  };

  render() {
    const { isLoggedIn } = this.props;
    return (
      <div className="header container">
        <div className="top container">
          {isLoggedIn ? (
            <>
              {" "}
              <Link
                to={"/"}
                className="logo"
                onClick={() => this.props.toggleMenuTab(0)}
              />
              <div className="right_part">
                <AdminAvatar attemptLogOut={this.attemptLogOut} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    isLoggedIn: store.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(isUserLoggedIn()),
    userLogOut: () => dispatch(isUserLoggedOut()),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainPage));
