import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./Success.css";
import { isUserLoggedOut } from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LOGIN } from "../../configs/constants";
import { cookies } from "../Login";

class MyVerticallyCenteredModal extends React.Component {
  logOut = () => {
    cookies.remove("refreshToken");
    localStorage.removeItem("accessToken");
    this.props.closemodal();
    this.props.userLogOut();
    this.props.history.push(LOGIN);
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
      >
        <Modal.Body>
          {window.location.pathname.includes("update") ? (
            <h4>Update {this.props.item}</h4>
          ) : window.location.pathname.includes("add") ? (
            <h4>Save new {this.props.item}</h4>
          ) : (
            <h4>Delete {this.props.item}</h4>
          )}

          {this.props.isSuccessModalOpened ? (
            <section className="c-container">
              {this.props.responseStatus && this.props.responseStatus > 400 ? (
                <>
                  <div className="o-circle c-container__circle o-circle__sign--failure">
                    <div className="o-circle__sign" />
                  </div>
                  {this.props.responseStatus === 401 ? (
                    <button
                      className="btn btn-primary w-100"
                      onClick={this.logOut}
                    >
                      Please logIn
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary w-100"
                      onClick={this.props.closemodal}
                    >
                      try again
                    </button>
                  )}
                </>
              ) : (
                <>
                  <div className="o-circle c-container__circle o-circle__sign--success">
                    <div className="o-circle__sign" />
                  </div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={this.props.closemodal}
                  >
                    ok
                  </button>
                </>
              )}
            </section>
          ) : window.location.pathname.includes("update") ? (
            <p>Are you sure you want save changes in {this.props.item}</p>
          ) : window.location.pathname.includes("add") ? (
            <p>Are you sure you want save new {this.props.item}</p>
          ) : (
            <p>{`Are you sure you want delete ${this.props.item}`}</p>
          )}
        </Modal.Body>
        {!this.props.isSuccessModalOpened ? (
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
            <Button onClick={this.props.onsend}>YES</Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}
const mapStateToProps = store => {
  return {
    users: store.users
  };
};
function mapDispatchToProps(dispatch) {
  return {
    userLogOut: () => dispatch(isUserLoggedOut())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyVerticallyCenteredModal));
