import React from "react";
import { connect } from "react-redux";
import {
  attemptGetArticleList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faAddressCard
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import {
  ADD_ARTICLE,
  ARTICLE_UPDATE_PAGE,
  LOGIN
} from "../../configs/constants";
import "./../../assets/css/pagination.css";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "article",
      countPerPage: 10
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetArticleList(this.props.language, this.state.countPerPage, pageNumber);
    });
  }

  componentDidMount() {
    this.props.toggleMenuTab(2);
    this.props.attemptGetArticleList(
      this.props.language,
        this.state.countPerPage,
      this.state.activePage
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.countPerPage !== prevState.countPerPage) {
      this.props.attemptGetArticleList(this.props.language, this.state.countPerPage, this.state.page);
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteArticle = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/article/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetArticleList(this.props.language, this.state.countPerPage, 1);
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  render() {
    const { articles } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Articles</title>
        </Helmet>
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {articles &&
                articles.list.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{item.title}</td>
                      <td>
                        <Link to={ARTICLE_UPDATE_PAGE(item.slug)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        {/*<div onClick={() => this.deleteArticle(item.Id)}>*/}
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.Id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {articles && articles.count > 10 ? (
            <div className={"mb-4"}>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={articles.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}
          <button className="btn btn-light mb-5">
            <Link className={"text-decoration-none"} to={ADD_ARTICLE}>
              <FontAwesomeIcon icon={faAddressCard} /> Add new article
            </Link>
          </button>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteArticle(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    articles: store.articles
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetArticleList: (lang, countPerPage, page) =>
      dispatch(attemptGetArticleList(lang, countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Articles));
