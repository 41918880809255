const PLATFORM_LANGUAGES = [
  "en",
  "ru",
  "es",
  "pt",
  "ar",
  "fa",
  "tr",
  "vi",
  "ja",
  "ID",
  "cs",
  "hi",
  "de",
  "it",
  "ms",
  "zh",
  "zh_cn"
];

export const parseDataForRequest = state => {
  const urlencoded = new URLSearchParams();

  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(`translations[${language}][name]`, lang.name);
    urlencoded.append(
      `translations[${language}][description]`,
      lang.description
    );
    urlencoded.append(
      `translations[${language}][fullDescription]`,
      lang.fullDescription
    );
  });

  const img = state.logo && state.logo.split(";base64,").pop();

  urlencoded.append("logo", img);
  urlencoded.append("sysname", state.sysname);
  urlencoded.append("show", state.show);

  state.platformDevices.forEach((device, index) => {
    if (!device.isAvailable) {
      return;
    }
    urlencoded.append("device[" + index + "][deviceId]", device.device_id);
    urlencoded.append(
      "device[" + index + "][deviceDownloadLink]",
      device.download
    );
    urlencoded.append("device[" + index + "][device_name]", device.device_name);
  });
  return urlencoded;
};
export const parseDataForRequestInHeadquarters = state => {
  const urlencoded = new URLSearchParams();

  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(`translations[${language}][name]`, lang.name);
  });

  urlencoded.append("sysname", state.sysname);

  return urlencoded;
};

export const parseDataForRequestInPayments = state => {
  const urlencoded = new URLSearchParams();

  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(`translations[${language}][name]`, lang.name);
  });

  urlencoded.append("sysname", state.sysname);

  return urlencoded;
};

export const parseDataForRequestInBroker = state => {
  var urlencoded = new URLSearchParams();

  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(
      `translation[${language}][description]`,
      lang.description
    );
  });
  urlencoded.append("broker[name]", state.name);
  urlencoded.append("broker[sysname]", state.sysname);

  const img = state.logo && state.logo.split(";base64,").pop();
  urlencoded.append("broker[logo]", img);

  urlencoded.append("broker[averageRate]", "dddddddddd");
  urlencoded.append("broker[founded]", state.founded);
  urlencoded.append("broker[brokerType]", "dfgfg");
  urlencoded.append("broker[maxLeverage]", state.maxLeverage);
  urlencoded.append("broker[timeZone]", "dfgfhg");
  urlencoded.append("broker[usTradersAccepted]", "dfhgfhg");
  urlencoded.append("broker[currencyPairsAmount]", state.currencyPairsAmount);
  urlencoded.append("broker[bonusProgram]", state.bonusProgram);
  urlencoded.append("broker[tradingContests]", state.tradingContests);
  urlencoded.append("broker[freeEducation]", state.freeEducation);
  urlencoded.append("broker[personalManager]", state.personalManager);
  urlencoded.append(
    "broker[islamicAccountsAvailable]",
    state.islamicAccountsAvailable
  );

  // state.currencies.split(",").forEach((currencies, index) => {
  //   urlencoded.append("currencies[" + index + "]", currencies);
  // });
  urlencoded.append("broker[tradingByPhone]", state.tradingByPhone);
  urlencoded.append("broker[affiliateProgram]", state.affiliateProgram);
  urlencoded.append("broker[freeAnalytics]", state.freeAnalytics);
  urlencoded.append(
    "broker[wideChoiceInstruments]",
    state.wideChoiceInstruments
  );
  urlencoded.append("broker[freeMarginInterest]", state.freeMarginInterest);
  urlencoded.append("broker[internalUrl]", state.internalUrl);
  urlencoded.append("broker[externalUrl]", state.externalUrl);
  urlencoded.append("broker[minSpread]", state.minSpread);
  urlencoded.append("broker[minDeposit]", state.minDeposit);
  // urlencoded.append("headquartersId[0]", "1");
  state.headquartersSelectedOption.forEach((headquarter, index) => {
    urlencoded.append("headquartersId[" + index + "]", headquarter.value);
  });
  state.paymentsSelectedOption.forEach((payment, index) => {
    urlencoded.append("paymentOptionsId[" + index + "]", payment.value);
  });
  state.demoPlatform.split(",").forEach((payment, index) => {
    urlencoded.append("demoPlatforms[" + index + "]", payment);
  });
  state.regulatedBy.split(",").forEach((payment, index) => {
    urlencoded.append("regulatedBy[" + index + "]", payment);
  });
  // urlencoded.append("regulatedBy[0][sysname]", "fhghg");
  urlencoded.append("tradingPlatformsId[0]", "1");
  urlencoded.append("tradingInstruments[0][sysname]", "fffff");
  urlencoded.append("customerSupportVia[0][sysname]", "ddd");
  urlencoded.append("spreadTypes[0][sysname]", "fixed");
  urlencoded.append("promotionsId[0]", "1");
  urlencoded.append("websiteLang[0][shortCode]", "dfgfg");
  urlencoded.append("websiteLang[0][name]", "fdgfgdg");

  return urlencoded;
};
export const parseDataBeforeForRequestInBroker = state => {
  var urlencoded = new URLSearchParams();

  urlencoded.append("broker[sysname]", state.sysname);

  urlencoded.append("broker[name]", state.company);
  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(
      `translation[${language}][description]`,
      lang.description
    );
  });
  // urlencoded.append("broker[logo]", "fgfg");
  const img = state.logo && state.logo.split(";base64,").pop();
  urlencoded.append("broker[logo]", img);
  // state.currencies.forEach((currencies, index) => {
  //   urlencoded.append("currencies[" + index + "]", currencies);
  // });
  urlencoded.append("broker[averageRate]", "dddddddddd");
  urlencoded.append("broker[founded]", state.founded);
  urlencoded.append("broker[brokerType]", "dfgfg");
  urlencoded.append("broker[maxLeverage]", state.maxLeverage);
  urlencoded.append("broker[timeZone]", "dfgfhg");
  urlencoded.append("broker[usTradersAccepted]", "dfhgfhg");
  urlencoded.append("broker[currencyPairsAmount]", state.currencyPairsAmount);
  urlencoded.append("broker[bonusProgram]", state.bonusProgram);
  urlencoded.append("broker[tradingContests]", state.tradingContests);
  urlencoded.append("broker[freeEducation]", state.freeEducation);
  urlencoded.append("broker[personalManager]", state.personalManager);
  urlencoded.append(
    "broker[islamicAccountsAvailable]",
    state.islamicAccountsAvailable
  );
  urlencoded.append("broker[tradingByPhone]", state.tradingByPhone);
  urlencoded.append("broker[affiliateProgram]", state.affiliateProgram);
  urlencoded.append("broker[freeAnalytics]", state.freeAnalytics);
  urlencoded.append(
    "broker[wideChoiceInstruments]",
    state.wideChoiceInstruments
  );
  urlencoded.append("broker[freeMarginInterest]", state.freeMarginInterest);
  urlencoded.append("broker[internalUrl]", state.internalUrl);
  urlencoded.append("broker[externalUrl]", state.externalUrl);
  urlencoded.append("broker[minSpread]", state.minSpread);
  urlencoded.append("broker[minDeposit]", state.minDeposit);
  // urlencoded.append("headquartersId[0]", "1");
  state.headquartersSelectedOption.forEach((headquarter, index) => {
    urlencoded.append("headquartersId[" + index + "]", headquarter.value);
  });
  state.paymentsSelectedOption.forEach((payment, index) => {
    urlencoded.append("paymentOptionsId[" + index + "]", payment.value);
  });
  state.demoPlatform.forEach((payment, index) => {
    urlencoded.append("demoPlatforms[" + index + "]", payment);
  });
  state.regulatedBy.forEach((payment, index) => {
    urlencoded.append("regulatedBy[" + index + "]", payment);
  });
  // urlencoded.append("regulatedBy[0][sysname]", "fhghg");
  urlencoded.append("tradingPlatformsId[0]", "1");
  urlencoded.append("tradingInstruments[0][sysname]", "fffff");
  urlencoded.append("customerSupportVia[0][sysname]", "ddd");
  urlencoded.append("spreadTypes[0][sysname]", "fixed");
  urlencoded.append("promotionsId[0]", "1");
  urlencoded.append("websiteLang[0][shortCode]", "dfgfg");
  urlencoded.append("websiteLang[0][name]", "fdgfgdg");

  return urlencoded;
};

export const upDateRequestInBroker = (state, currentLanguage) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("broker[sysname]", state.sysname);

  urlencoded.append("broker[name]", state.company);

  PLATFORM_LANGUAGES.forEach(language => {
    const lang = state[language];
    urlencoded.append(
      `translation[${language}][description]`,
      lang.description
    );
  });

  const img = state.logo && state.logo.split(";base64,").pop();
  urlencoded.append("broker[logo]", img);

  // state.currencies.length &&
  //   state.currencies.forEach((currencies, index) => {
  //     urlencoded.append("currencies[" + index + "]", currencies);
  //   });
  urlencoded.append("broker[averageRate]", "dddddddddd");
  urlencoded.append("broker[founded]", state.founded);
  urlencoded.append("broker[brokerType]", "dfgfg");
  urlencoded.append("broker[maxLeverage]", state.maxLeverage);
  urlencoded.append("broker[timeZone]", "dfgfhg");
  urlencoded.append("broker[usTradersAccepted]", "dfhgfhg");
  urlencoded.append("broker[currencyPairsAmount]", state.currencyPairsAmount);
  urlencoded.append("broker[bonusProgram]", state.bonusProgram);
  urlencoded.append("broker[tradingContests]", state.tradingContests);
  urlencoded.append("broker[freeEducation]", state.freeEducation);
  urlencoded.append("broker[personalManager]", state.personalManager);
  urlencoded.append(
    "broker[islamicAccountsAvailable]",
    state.islamicAccountsAvailable
  );
  urlencoded.append("broker[tradingByPhone]", state.tradingByPhone);
  urlencoded.append("broker[affiliateProgram]", state.affiliateProgram);
  urlencoded.append("broker[freeAnalytics]", state.freeAnalytics);
  urlencoded.append(
    "broker[wideChoiceInstruments]",
    state.wideChoiceInstruments
  );
  urlencoded.append("broker[freeMarginInterest]", state.freeMarginInterest);
  urlencoded.append("broker[internalUrl]", state.internalUrl);
  urlencoded.append("broker[externalUrl]", state.externalUrl);
  urlencoded.append("broker[minSpread]", state.minSpread);
  urlencoded.append("broker[minDeposit]", state.minDeposit);
  // urlencoded.append("headquartersId[0]", "1");
  state.headquartersSelectedOption.forEach((headquarter, index) => {
    urlencoded.append("headquartersId[" + index + "]", headquarter.value);
  });
  state.paymentsSelectedOption.forEach((payment, index) => {
    urlencoded.append("paymentOptionsId[" + index + "]", payment.value);
  });
  state.demoPlatform.length &&
    [state.demoPlatform].forEach((payment, index) => {
      urlencoded.append("demoPlatforms[" + index + "]", payment);
    });
  state.regulatedBy.length &&
    [state.regulatedBy].forEach((payment, index) => {
      urlencoded.append("regulatedBy[" + index + "]", payment);
    });
  // urlencoded.append("regulatedBy[0][sysname]", "fhghg");
  urlencoded.append("tradingPlatformsId[0]", "1");
  urlencoded.append("tradingInstruments[0][sysname]", "fffff");
  urlencoded.append("customerSupportVia[0][sysname]", "ddd");
  urlencoded.append("spreadTypes[0][sysname]", "fixed");
  urlencoded.append("promotionsId[0]", "1");
  urlencoded.append("websiteLang[0][shortCode]", "dfgfg");
  urlencoded.append("websiteLang[0][name]", "fdgfgdg");

  return urlencoded;
};
