import {
  ADMIN_BROKER,
  ADMIN_HEADQUARTER,
  ADMIN_PAYMENT,
  TRADING_PLATFORM
} from "../configs/constants";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export default class GeneralApi {
  constructor() {
    this.env = process.env.REACT_APP_API_LINK || "development";
  }

  getArticleList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/articles?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getUsersList(countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/user?countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getPromotionsList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/promotion/list?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getPromotion(id, lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/promotion/${id}?lang=${lang}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getTradingPlatformsList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/trading-platform?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getExpertList(countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/experts?countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getHeadquartersList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/headquarter/list?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }
  getHeadquartersFullList() {
    return fetch(process.env.REACT_APP_API_URL + `/headquarter/list?lang=en`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json"
      }
    });
  }
  getPaymentOptionsFullList() {
    return fetch(process.env.REACT_APP_API_URL + `/payment/list?lang=en`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json"
      }
    });
  }

  getPaymentOptionsList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/payment/list?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }

  getBrokersList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/broker?lang=${lang}&countPerPage=${countPerPage}&page=${page}&orderBy=asc&sortBy=id`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
    );
  }

  saveTradingPlatform(body) {
    return fetch(process.env.REACT_APP_API_URL + `${TRADING_PLATFORM}`, {
      body,
      method: "post"
    });
  }
  upDateTradingPlatform(body, sysname) {
    return fetch(
      process.env.REACT_APP_API_URL + `${TRADING_PLATFORM}/${sysname}`,
      { body, method: "post" }
    );
  }
  saveNewBroker(body) {
    return fetch(process.env.REACT_APP_API_URL + `${ADMIN_BROKER}`, {
      body,
      method: "post",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
  }
  updateNewBroker(body, brokerSysname) {
    return fetch(
      process.env.REACT_APP_API_URL + `${ADMIN_BROKER}/${brokerSysname}`,
      { body, method: "post" }
    );
  }
  saveHeadQuarters(body) {
    return fetch(process.env.REACT_APP_API_URL + `${ADMIN_HEADQUARTER}`, {
      body,
      method: "post"
    });
  }
  savePayments(body) {
    return fetch(process.env.REACT_APP_API_URL + `${ADMIN_PAYMENT}`, {
      body,
      method: "post"
    });
  }
  saveAccountType(body, sysname) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/admin/broker/account-type/add/${sysname}`,
      {
        body,
        method: "post"
      }
    );
  }
  updateAccountType(body, sysname) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/admin/broker/account-type/update/${sysname}`,
      {
        body,
        method: "post"
      }
    );
  }
  updateHeadQuarters(body, id) {
    return fetch(process.env.REACT_APP_API_URL + `${ADMIN_HEADQUARTER}/${id}`, {
      body,
      method: "post"
    });
  }
  updatePayments(body, id) {
    return fetch(process.env.REACT_APP_API_URL + `${ADMIN_PAYMENT}/${id}`, {
      body,
      method: "post"
    });
  }

  getBrokerAccountTypeById(id) {
    return fetch(
      process.env.REACT_APP_API_URL + `/admin/broker/account-type/update/${id}`
    );
  }
  getPlatformBySysname(name, lang) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/trading-platform/details?lang=${lang}&sysname=${name}`
    );
  }
  getBrokerBySysname(sysname, lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/broker/${sysname}?lang=${lang}`
    );
  }
}
