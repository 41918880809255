/** login/logout **/

export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const isUserLoggedIn = () => ({
  type: USER_LOGGED_IN
});
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const isUserLoggedOut = () => ({
  type: USER_LOGGED_OUT
});

/** articles **/

export const ATTEMPT_GET_ARTICLE_LIST = "ATTEMPT_GET_ARTICLE_LIST";
export const GET_ARTICLE_LIST_SUCCESS = "GET_ARTICLE_LIST_SUCCESS";
export const GET_ARTICLE_LIST_FAILED = "GET_ARTICLE_LIST_FAILED";

export const attemptGetArticleList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_ARTICLE_LIST,
  payload: { lang, countPerPage, page }
});
export const getArticleListSuccess = articles => ({
  type: GET_ARTICLE_LIST_SUCCESS,
  payload: { articles }
});
export const getArticleListFailed = error => ({
  type: GET_ARTICLE_LIST_FAILED,
  payload: { error }
});

/** experts **/

export const ATTEMPT_GET_EXPERT_LIST = "ATTEMPT_GET_EXPERT_LIST";
export const GET_EXPERT_LIST_SUCCESS = "GET_EXPERT_LIST_SUCCESS";
export const GET_EXPERT_LIST_FAILED = "GET_EXPERT_LIST_FAILED";

export const attemptGetExpertList = (countPerPage, page) => ({
  type: ATTEMPT_GET_EXPERT_LIST,
  payload: { countPerPage, page }
});
export const getExpertListSuccess = experts => ({
  type: GET_EXPERT_LIST_SUCCESS,
  payload: { experts }
});
export const getExpertListFailed = error => ({
  type: GET_EXPERT_LIST_FAILED,
  payload: { error }
});

/** brokers **/

export const ATTEMPT_GET_BROKER_LIST = "ATTEMPT_GET_BROKER_LIST";
export const GET_BROKER_LIST_SUCCESS = "GET_BROKER_LIST_SUCCESS";
export const GET_BROKER_LIST_FAILED = "GET_BROKER_LIST_FAILED";

export const attemptGetBrokersList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_BROKER_LIST,
  payload: { lang, countPerPage, page }
});
export const getExpertBrokersSuccess = brokers => ({
  type: GET_BROKER_LIST_SUCCESS,
  payload: { brokers }
});
export const getBrokersListFailed = error => ({
  type: GET_BROKER_LIST_FAILED,
  payload: { error }
});

export const TOGGLE_MENU_TABS = "TOGGLE_MENU_TABS";
export const toggleMenuTab = menuActiveTab => ({
  type: TOGGLE_MENU_TABS,
  payload: { menuActiveTab }
});

/** users **/

export const ATTEMPT_GET_USERS_LIST = "ATTEMPT_GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILED = "GET_USERS_LIST_FAILED";

export const attemptGetUsersList = (countPerPage, page) => ({
  type: ATTEMPT_GET_USERS_LIST,
  payload: { countPerPage, page }
});
export const getUsersListSuccess = users => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: { users }
});
export const getUsersListFailed = error => ({
  type: GET_USERS_LIST_FAILED,
  payload: { error }
});
/** promotions **/

export const ATTEMPT_GET_PROMOTIONS_LIST = "ATTEMPT_GET_PROMOTIONS_LIST";
export const GET_PROMOTIONS_LIST_SUCCESS = "GET_PROMOTIONS_LIST_SUCCESS";
export const GET_PROMOTIONS_LIST_FAILED = "GET_PROMOTIONS_LIST_FAILED";

export const attemptGetPromotionsList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_PROMOTIONS_LIST,
  payload: { lang, countPerPage, page }
});
export const getPromotionsListSuccess = promotions => ({
  type: GET_PROMOTIONS_LIST_SUCCESS,
  payload: { promotions }
});
export const getPromotionsListFailed = error => ({
  type: GET_PROMOTIONS_LIST_FAILED,
  payload: { error }
});
/** TradingPlatforms **/

export const ATTEMPT_GET_TRADING_PLATFORMS_LIST =
  "ATTEMPT_GET_TRADING_PLATFORMS_LIST";
export const GET_TRADING_PLATFORMS_LIST_SUCCESS =
  "GET_TRADING_PLATFORMS_LIST_SUCCESS";
export const GET_TRADING_PLATFORMS_LIST_FAILED =
  "GET_TRADING_PLATFORMS_LIST_FAILED";

export const attemptGetTradingPlatformsList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_TRADING_PLATFORMS_LIST,
  payload: { lang, countPerPage, page }
});
export const getTradingPlatformsListSuccess = tradingPlatforms => ({
  type: GET_TRADING_PLATFORMS_LIST_SUCCESS,
  payload: { tradingPlatforms }
});
export const getTradingPlatformsListFailed = error => ({
  type: GET_TRADING_PLATFORMS_LIST_FAILED,
  payload: { error }
});

/** HeadquartersList **/

export const ATTEMPT_GET_HEADQUARTERS_LIST = "ATTEMPT_GET_HEADQUARTERS_LIST";
export const GET_BROKER_HEADQUARTERS_LIST_SUCCESS =
  "GET_BROKER_HEADQUARTERS_LIST_SUCCESS";
export const GET_BROKER_HEADQUARTERS_LIST_FAILED =
  "GET_BROKER_HEADQUARTERS_LIST_FAILED";

export const attemptGetHeadquartersList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_HEADQUARTERS_LIST,
  payload: { lang, countPerPage, page }
});
export const getHeadquartersListSuccess = headquartersList => ({
  type: GET_BROKER_HEADQUARTERS_LIST_SUCCESS,
  payload: { headquartersList }
});
export const getHeadquartersListFailed = error => ({
  type: GET_BROKER_HEADQUARTERS_LIST_FAILED,
  payload: { error }
});
/** FULL HeadquartersList **/

export const GET_HEADQUARTERS_FULL_LIST = "GET_HEADQUARTERS_FULL_LIST";
export const GET_HEADQUARTERS_FULL_LIST_SUCCESS =
  "GET_HEADQUARTERS_FULL_LIST_SUCCESS";
export const GET_HEADQUARTERS_FULL_LIST_FAILED =
  "GET_HEADQUARTERS_FULL_LIST_FAILED";

export const getHeadquartersFullList = () => ({
  type: GET_HEADQUARTERS_FULL_LIST
});
export const getHeadquartersFullListSuccess = headquartersFullList => ({
  type: GET_HEADQUARTERS_FULL_LIST_SUCCESS,
  payload: { headquartersFullList }
});
export const getHeadquartersFullListFailed = error => ({
  type: GET_HEADQUARTERS_FULL_LIST_FAILED,
  payload: { error }
});

/** PaymentOptionsList **/

export const ATTEMPT_GET_PAYMENT_OPTIONS_LIST =
  "ATTEMPT_GET_PAYMENT_OPTIONS_LIST";
export const GET_BROKER_PAYMENT_OPTIONS_LIST_SUCCESS =
  "GET_BROKER_PAYMENT_OPTIONS_LIST_SUCCESS";
export const GET_BROKER_PAYMENT_OPTIONS_LIST_FAILED =
  "GET_BROKER_PAYMENT_OPTIONS_LIST_FAILED";

export const attemptGetPaymentOptionsList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_PAYMENT_OPTIONS_LIST,
  payload: { lang, countPerPage, page }
});
export const getPaymentOptionsListSuccess = paymentsList => ({
  type: GET_BROKER_PAYMENT_OPTIONS_LIST_SUCCESS,
  payload: { paymentsList }
});
export const getPaymentOptionsListFailed = error => ({
  type: GET_BROKER_PAYMENT_OPTIONS_LIST_FAILED,
  payload: { error }
});

/** FULL PaymentOptionsList **/

export const GET_PAYMENT_OPTIONS_FULL_LIST = "GET_PAYMENT_OPTIONS_FULL_LIST";
export const GET_PAYMENT_OPTIONS_FULL_LIST_SUCCESS =
  "GET_PAYMENT_OPTIONS_FULL_LIST_SUCCESS";
export const GET_PAYMENT_OPTIONS_FULL_LIST_FAILED =
  "GET_PAYMENT_OPTIONS_FULL_LIST_FAILED";

export const getPaymentOptionsFullList = () => ({
  type: GET_PAYMENT_OPTIONS_FULL_LIST
});
export const getPaymentOptionsFullListSuccess = paymentsFullList => ({
  type: GET_PAYMENT_OPTIONS_FULL_LIST_SUCCESS,
  payload: { paymentsFullList }
});
export const getPaymentOptionsFullListFailed = error => ({
  type: GET_PAYMENT_OPTIONS_FULL_LIST_FAILED,
  payload: { error }
});

/** articles **/

export const CHANGED_BROKER_ID = "CHANGED_BROKER_ID";

export const changedBrokerId = currentBrokerId => ({
  type: CHANGED_BROKER_ID,
  payload: { currentBrokerId }
});

export const SET_BROKER_SYSNAME = "SET_BROKER_SYSNAME";
export const setBrokerSysname = currentBrokerSysname => ({
  type: SET_BROKER_SYSNAME,
  payload: { currentBrokerSysname }
});

export const SET_CURRENT_BROKER_ID = "SET_CURRENT_BROKER_ID";
export const setCurrentBrokerId = currentBrokerID => ({
  type: SET_CURRENT_BROKER_ID,
  payload: { currentBrokerID }
});
