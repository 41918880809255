import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faArrowLeft,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import {
  ADD_PAYMENT_OPTION,
  BROKERS,
  LOGIN,
  UPDATE_BROKER_DATA,
  UPDATE_PAYMENT_OPTION_BY_ID
} from "../../../configs/constants";
import MyVerticallyCenteredModal from "../../popup/MyVerticallyCenteredModal";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  attemptGetPaymentOptionsList,
  toggleMenuTab
} from "../../../redux/actions/indexActions";
import Pagination from "react-js-pagination";
class PaymentOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "payment option",
      countPerPage: 10
    };
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deletePaymentOption = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/payment/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetPaymentOptionsList(this.props.language, this.state.countPerPage, 1);
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
    this.setState({ isShowEditBlock: false });
  };

  componentDidMount() {
    const { page } = this.state;
    const { language } = this.props;
    this.props.attemptGetPaymentOptionsList(language, this.state.countPerPage, page);
    this.props.toggleMenuTab(1);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetPaymentOptionsList(
        this.props.language,
          this.state.countPerPage,
        pageNumber
      );
    });
  }
  render() {
    const { paymentsList } = this.props;
    console.log(paymentsList, "paymentsList");

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Payment Options</title>
        </Helmet>
        <div className="container ">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {paymentsList &&
                paymentsList.list.length &&
                paymentsList.list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{`${item.sysname}`}</td>
                      <td>
                        <Link to={UPDATE_PAYMENT_OPTION_BY_ID(item.id)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {paymentsList && paymentsList.count > this.state.countPerPage ? (
            <div class="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={paymentsList.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}
          <button
            className="btn btn-light"
            onClick={() => {
              this.props.currentBrokerSysname
                ? this.props.history.push(
                    UPDATE_BROKER_DATA(this.props.currentBrokerSysname)
                  )
                : this.props.history.push(BROKERS);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <button className="btn btn-light">
            <Link class="text-decoration-none" to={ADD_PAYMENT_OPTION}>
              <FontAwesomeIcon icon={faAddressCard} /> Add new payment option
            </Link>
          </button>

          {/*<button className="btn btn-light">*/}
          {/*    <Link to={UPDATE_BROKER_DATA( this.props.match.params.id)}>*/}
          {/*        /!*<FontAwesomeIcon icon={faAddressCard} />*!/*/}
          {/*        Back to broker*/}
          {/*    </Link>*/}
          {/*</button>*/}
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deletePaymentOption(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    paymentsList: store.paymentsList,
    language: store.language,
    currentBrokerSysname: store.currentBrokerSysname
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetPaymentOptionsList: (lang, countPerPage, page) =>
      dispatch(attemptGetPaymentOptionsList(lang, countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentOptions));
