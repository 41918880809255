import {
  GET_ARTICLE_LIST_FAILED,
  GET_ARTICLE_LIST_SUCCESS,
  GET_BROKER_LIST_SUCCESS,
  GET_EXPERT_LIST_SUCCESS,
  TOGGLE_MENU_TABS,
  GET_USERS_LIST_SUCCESS,
  GET_PROMOTIONS_LIST_SUCCESS,
  GET_TRADING_PLATFORMS_LIST_SUCCESS,
  GET_BROKER_HEADQUARTERS_LIST_SUCCESS,
  GET_BROKER_PAYMENT_OPTIONS_LIST_SUCCESS,
  GET_HEADQUARTERS_FULL_LIST_SUCCESS,
  GET_PAYMENT_OPTIONS_FULL_LIST_SUCCESS,
  CHANGED_BROKER_ID,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  SET_BROKER_SYSNAME,
  SET_CURRENT_BROKER_ID
} from "../actions/indexActions";

const pathName = window.location.pathname;
const menuActiveTab = `${
  pathName.includes("/admin/brokers")
    ? 1
    : pathName.includes("/admin/article")
    ? 2
    : pathName.includes("/admin/trading")
    ? 3
    : pathName.includes("/admin/expert")
    ? 4
    : pathName.includes("/admin/users")
    ? 5
    : pathName.includes("/admin/promotions")
    ? 6
    : 7
}`;

const initialState = {
  isLoggedIn: false,
  articles: null,
  language: "en",
  experts: null,
  brokers: null,
  user: null,
  tradingPlatforms: null,
  menuActiveTab: +menuActiveTab,
  headquartersList: null,
  paymentsList: null,
  headquartersFullList: null,
  paymentsFullList: null,
  currentBrokerId: null,
  currentBrokerID: null,
  currentBrokerSysname: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        articles: payload.articles
      };

    case USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false
      };
    case GET_ARTICLE_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_EXPERT_LIST_SUCCESS:
      return {
        ...state,
        experts: payload.experts
      };

    case GET_BROKER_LIST_SUCCESS:
      return {
        ...state,
        brokers: payload.brokers
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        users: payload.users
      };
    case GET_PROMOTIONS_LIST_SUCCESS:
      return {
        ...state,
        promotions: payload.promotions
      };
    case GET_TRADING_PLATFORMS_LIST_SUCCESS:
      return {
        ...state,
        tradingPlatforms: payload.tradingPlatforms
      };
    case GET_BROKER_HEADQUARTERS_LIST_SUCCESS:
      return {
        ...state,
        headquartersList: payload.headquartersList
      };
    case GET_HEADQUARTERS_FULL_LIST_SUCCESS:
      return {
        ...state,
        headquartersFullList: payload.headquartersFullList
      };
    case GET_BROKER_PAYMENT_OPTIONS_LIST_SUCCESS:
      return {
        ...state,
        paymentsList: payload.paymentsList
      };
    case GET_PAYMENT_OPTIONS_FULL_LIST_SUCCESS:
      return {
        ...state,
        paymentsFullList: payload.paymentsFullList
      };

    case TOGGLE_MENU_TABS:
      return {
        ...state,
        menuActiveTab: payload.menuActiveTab
      };
    case CHANGED_BROKER_ID:
      return {
        ...state,
        currentBrokerId: payload.currentBrokerId
      };
    case SET_BROKER_SYSNAME:
      return {
        ...state,
        currentBrokerSysname: payload.currentBrokerSysname
      };
    case SET_CURRENT_BROKER_ID:
      return {
        ...state,
        currentBrokerID: payload.currentBrokerID
      };

    default:
      return state;
  }
};
