import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  ARTICLES,
  BROKERS,
  EXPERTS,
  USERS,
  TRADING_PLATFORM,
  PROMOTIONS
} from "../../configs/constants";
import "./menu.css";
import { connect } from "react-redux";
import { toggleMenuTab } from "../../redux/actions/indexActions";

class MenuNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag_none: "none"
    };
  }

  openNav = () => {
    document.getElementById("mobMenu").style.width = "300px";
    var body = document.body;
    body.classList.add("body_hover");
    if (document.getElementById("fil")) {
      document.getElementById("fil").style.width = "0";
      var content = document.getElementsByClassName("content")[0];
      content.classList.remove("content_hover");
      document
        .getElementsByClassName("black_opacity")[0]
        .classList.remove("active");
    }

    document.getElementsByTagName("body")[0].addEventListener("click", () => {
      document.getElementById("mobMenu").style.width = "0";
      var body = document.body;
      body.classList.remove("body_hover");
    });
  };

  closeNav = () => {
    document.getElementById("mobMenu").style.width = "0";
    var body = document.body;
    body.classList.remove("body_hover");
  };

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { menuActiveTab } = this.props;

    return (
      <div className="menu">
        <div className="container">
          <div>
            <div id="menu_button" onClick={this.openNav.bind(this)}>
              &#9776;
            </div>
          </div>
          <div className="top_menu_wrapper">
            <ul
              id="mobMenu"
              className="menu_list"
              onClick={this.closeNav.bind(this)}
            >
              <li>
                <div className="closebtn">&times;</div>
              </li>
              <li className={`${1 === menuActiveTab ? "activeTab " : ""}`}>
                <Link onClick={() => this.props.toggleMenuTab(1)} to={BROKERS}>
                  Broker
                </Link>
              </li>
              <li className={`${2 === menuActiveTab ? "activeTab " : ""}`}>
                <Link onClick={() => this.props.toggleMenuTab(2)} to={ARTICLES}>
                  Article
                </Link>
              </li>
              <li className={`${3 === menuActiveTab ? "activeTab " : ""}`}>
                <Link
                  onClick={() => this.props.toggleMenuTab(3)}
                  to={TRADING_PLATFORM}
                >
                  Trading Platform
                </Link>
              </li>
              <li className={`${4 === menuActiveTab ? "activeTab " : ""}`}>
                <Link onClick={() => this.props.toggleMenuTab(4)} to={EXPERTS}>
                  Experts
                  {/*{(t('ANALYTICS'))}*/}
                </Link>
              </li>
              <li className={`${5 === menuActiveTab ? "activeTab " : ""}`}>
                <Link onClick={() => this.props.toggleMenuTab(5)} to={USERS}>
                  Users
                </Link>
              </li>
              <li className={`${6 === menuActiveTab ? "activeTab " : ""}`}>
                <Link
                  onClick={() => this.props.toggleMenuTab(6)}
                  to={PROMOTIONS}
                >
                  Promotions
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    language: state.language,
    menuActiveTab: state.menuActiveTab
  }),
  dispatch => ({
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  })
)(withRouter(MenuNav));
