import React from "react";
import Coverflow from "react-coverflow";
import BROKER from "./../../assets/images/ADMINPIC/BROKER_PAGE.png";
import ARTICLE from "./../../assets/images/ADMINPIC/ARTICLES.png";
import EXPERT from "./../../assets/images/ADMINPIC/EXPERT.png";
import TOP_PROMOTION from "./../../assets/images/ADMINPIC/TOP_PROMOTIONS.png";
import PLATFORM from "./../../assets/images/ADMINPIC/TRADNG_PLATFORM.png";
import "./main.css";
import {
  ARTICLES,
  BROKERS,
  EXPERTS,
  PROMOTIONS,
  TRADING_PLATFORM
} from "../../configs/constants";
import { Helmet } from "react-helmet";

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="myCarusel">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel</title>
        </Helmet>
        <Coverflow
          width="960"
          height="700"
          displayQuantityOfSide={2}
          navigation={false}
          enableScroll={true}
          clickable={true}
          active={0}
        >
          {/*<div role="menuitem" tabIndex="0" className="myCarusel">*/}
            <img
              src={BROKER}
              alt="Brokers"
              style={{
                display: "block",
                width: "100%"
              }}
              onClick={() => {
                this.props.history.push(BROKERS);
              }}
            />
          {/*</div>*/}
          <img
            src={PLATFORM}
            alt="Trading platform"
            onClick={() => {
              this.props.history.push(TRADING_PLATFORM);
            }}
            style={{
              display: "block",
              width: "100%"
            }}
          />
          <img
            src={ARTICLE}
            alt="Articles"
            onClick={() => {
              this.props.history.push(ARTICLES);
            }}
            style={{
              display: "block",
              width: "100%"
            }}
          />
          <img
            src={EXPERT}
            alt="Expert corners"
            onClick={() => {
              this.props.history.push(EXPERTS);
            }}
            style={{
              display: "block",
              width: "100%"
            }}
          />
          <img
            src={TOP_PROMOTION}
            alt="Top promotion"
            onClick={() => {
              this.props.history.push(PROMOTIONS);
            }}
            style={{
              display: "block",
              width: "100%"
            }}
          />
        </Coverflow>
      </div>
    );
  }
}

export default AdminPage;
