import React from "react";
import "./brokerComponents/broker_page.css";
import "./../tradingPlatform/AddBroker.css";
import "./../../assets/css/main.css";
import MyVerticallyCenteredModal from "./../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import HeadquartersModal from "../popup/Headquarters/HeadquartersModal";
import {
  changedBrokerId,
  getHeadquartersFullList,
  getPaymentOptionsFullList,
  setBrokerSysname,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import PaymentOptionModal from "../popup/PaymentOption/PaymentOptionModal";
import { PLATFORM_LANGUAGES } from "../popup/Headquarters/CreateHeadquartersModal";
import { upDateRequestInBroker } from "../tradingPlatform/util";
import GeneralApi from "../../api";
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPES_BY_ID, ADMIN_BROKER,
  BROKER_HEADQUARTERS,
  BROKERS,
  PAYMENT_OPTIONS
} from "../../configs/constants";
import { Helmet } from "react-helmet";

const API = new GeneralApi();

const PLATFORM_DETAILS_INPUTS = [
  {
    cols: "150",
    name: "description",
    rows: "3",
    placeholder: "Broker short description",
    type: "textarea"
  }
];

class UpdateBroker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getDefaultLanguagesParams(),
      logo: "",
      showOptions: false,
      arrow: "",
      showHeadquarters: false,
      arrowHeadquarters: "",
      headquarters: [],
      headquartersValue: "",
      paymentOptions: [],
      paymentOptionsValue: "",
      bonusProgram: 0,
      tradingContests: 0,
      freeEducation: 0,
      personalManager: 0,
      islamicAccountsAvailable: 0,
      tradingByPhone: 0,
      affiliateProgram: 0,
      freeAnalytics: 0,
      wideChoiceInstruments: 0,
      freeMarginInterest: 0,
      founded: "",
      demoPlatform: "",
      // currencies: "",
      maxLeverage: "",
      currencyPairsAmount: "",
      open: false,
      isSuccessModalOpened: false,
      item: this.props.match.params.sysname,
      responseStatus: null,
      sysname: "",
      ID: null,
      headquartersSelectedOption: [],
      paymentsSelectedOption: [],
      isPaymentOptionModalOpened: false,
      langBtnIndex: 1,
      averageRate: "",
      currentLanguage: PLATFORM_LANGUAGES[0],
      headquartersFromData: [],
      accountTypes: [],
      internalUrl: "",
      externalUrl: "",
      minSpread: "",
      minDeposit: "",
      currentBrokerId: null
    };

    this.openModal = this.openModal.bind(this);
    this.closemodal = this.closemodal.bind(this);
  }

  getDefaultLanguagesParams() {
    const languages = {};
    PLATFORM_LANGUAGES.forEach(lang => {
      languages[lang] = {
        description: ""
      };
    });
    return languages;
  }

  openModal() {
    this.setState({ open: true });
  }

  closemodal() {
    this.setState({ open: false });
  }

  close = () => {
    this.setState(
      {
        open: false,
        isSuccessModalOpened: false,
        showOptions: false,
        arrow: "",
        showHeadquarters: false,
        arrowHeadquarters: "",

        headquarters: [],
        headquartersValue: "",
        paymentOptions: [],
        paymentOptionsValue: "",

        bonusProgram: 0,
        tradingContests: 0,
        freeEducation: 0,
        personalManager: 0,
        islamicAccountsAvailable: 0,
        tradingByPhone: 0,
        affiliateProgram: 0,
        freeAnalytics: 0,
        wideChoiceInstruments: 0,
        freeMarginInterest: 0,

        name: "",
        sysname: "",
        description: "",
        founded: "",
        demoPlatform: "",
        // currencies: "",
        maxLeverage: "",
        currencyPairsAmount: "",
        regulatedBy: "",
        internalUrl: "",
        externalUrl: "",
        minSpread: "",
        minDeposit: ""
      },
      () => this.props.history.push(BROKERS)
    );
  };
  handleChangeHeadquarters = headquartersSelectedOption => {
    this.setState({ headquartersSelectedOption });
  };
  handleChangePayments = paymentsSelectedOption => {
    this.setState({ paymentsSelectedOption });
  };
  toggleHeadquartersModal = () => {
    this.state.isHeadquartersModalOpened
      ? this.setState({ isHeadquartersModalOpened: false })
      : this.setState({ isHeadquartersModalOpened: true });
  };
  togglePaymentOptionsModal = () => {
    this.state.isPaymentOptionModalOpened
      ? this.setState({ isPaymentOptionModalOpened: false })
      : this.setState({ isPaymentOptionModalOpened: true });
  };
  handlePlatformInputsChange({ target }) {
    const { currentLanguage } = this.state;
    const { name, value } = target;
    this.setState({
      [currentLanguage]: {
        ...this.state[currentLanguage],
        [name]: value
      }
    });
  }
  getBrokerData = () => {
    return API.getBrokerBySysname(
      this.props.match.params.sysname,
      this.state.currentLanguage
    )
      .then(res => res.json())
      .then(data => {
        {
          this.setState({
            headquarters: data.headquarters,
            logo: data.logo,
            headquartersValue: "",
            paymentOptions: data.paymentOptions,
            paymentOptionsValue: "",
            bonusProgram: +data.advantages[0].bonusProgram,
            tradingContests: +data.advantages[1].tradingContests,
            freeEducation: +data.advantages[2].freeEducation,
            personalManager: +data.advantages[3].personalManager,
            islamicAccountsAvailable: +data.advantages[4]
              .islamicAccountsAvailable,
            tradingByPhone: +data.advantages[5].tradingByPhone,
            affiliateProgram: +data.advantages[6].affiliateProgram,
            freeAnalytics: +data.advantages[7].freeAnalytics,
            wideChoiceInstruments: +data.advantages[8].wideChoiceInstruments,
            freeMarginInterest: +data.advantages[9].freeMarginInterest,
            founded: data.founded,
            demoPlatform: data.demoPlatform.join(),
            // currencies: data.currencies,
            maxLeverage: +data.maxLeverage,
            currencyPairsAmount: data.currencyPairsAmount,
            responseStatus: null,
            sysname: data.sysname,
            headquartersSelectedOption: data.headquarters,
            paymentsSelectedOption: data.paymentOptions,
            isPaymentOptionModalOpened: false,
            langBtnIndex: 1,
            lang: PLATFORM_LANGUAGES[0],
            averageRate: data.averageRate,
            regulatedBy: data.regulatedBy.join(),
            company: this.props.match.params.sysname,
            ID: data.id,
            headquartersFromData: data.headquarters,
            [this.state.currentLanguage]: {
              ...this.state[this.state.currentLanguage],
              description: data.description
            },
            accountTypes: data.accountTypes,
            internalUrl: data.internalUrl,
            externalUrl: data.externalUrl,
            minSpread: data.minSpread,
            minDeposit: data.minDeposit,
            name: data.sysname,
            currentBrokerId: data.id
          });
        }
      })
      .catch(err => console.log(err));
  };

  // sendRequest = () => {
  //   const {
  //     responseStatus,
  //     isSuccessModalOpened,
  //     open,
  //     ...mainData
  //   } = this.state;
  //   API.updateNewBroker(
  //     upDateRequestInBroker(mainData, this.state.currentLanguage),
  //     this.state.ID
  //   )
  //     .then(response => {
  //       response.text();
  //       this.setState({ responseStatus: response.status });
  //     })
  //     // .then(result => console.log(result))
  //     .catch(error => console.log("error", error));
  //
  //   this.setState({ isSuccessModalOpened: true });
  // };


  sendRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
        "Authorization",
        `token ${localStorage.getItem("accessToken")}`
    );

    var urlencoded = new URLSearchParams();

    urlencoded.append(
        "translation[en][description]",
        this.state.en.description
    );
    urlencoded.append(
        "translation[ru][description]",
        this.state.ru.description
    );

    ///////////////////////////////////es////////////////////////////////////////
    urlencoded.append(
        "[es][description]",
        this.state.es.description
    );
    ///////////////////////////////////pt////////////////////////////////////////
    urlencoded.append(
        "translation[pt][description]",
        this.state.pt.description
    );
    ///////////////////////////////////ar////////////////////////////////////////
    urlencoded.append(
        "translation[ar][description]",
        this.state.ar.description
    );
    ///////////////////////////////////fa////////////////////////////////////////
    urlencoded.append(
        "translation[fa][description]",
        this.state.fa.description
    );
    ///////////////////////////////////tr////////////////////////////////////////
    urlencoded.append(
        "translation[tr][description]",
        this.state.tr.description
    );
    ///////////////////////////////////vi////////////////////////////////////////
    urlencoded.append(
        "translation[vi][description]",
        this.state.vi.description
    );
    ///////////////////////////////////ja////////////////////////////////////////
    urlencoded.append(
        "translation[ja][description]",
        this.state.ja.description
    );
    ///////////////////////////////////ID////////////////////////////////////////
    urlencoded.append(
        "translation[ID][description]",
        this.state.ID.description
    );
    ///////////////////////////////////cs////////////////////////////////////////
    urlencoded.append(
        "translation[cs][description]",
        this.state.cs.description
    );
    ///////////////////////////////////hi////////////////////////////////////////
    urlencoded.append(
        "translation[hi][description]",
        this.state.hi.description
    );
    ///////////////////////////////////de////////////////////////////////////////
    urlencoded.append(
        "translation[de][description]",
        this.state.de.description
    );
    ///////////////////////////////////it////////////////////////////////////////
    urlencoded.append(
        "translation[it][description]",
        this.state.it.description
    );
    ///////////////////////////////////ms////////////////////////////////////////
    urlencoded.append(
        "translation[ms][description]",
        this.state.ms.description
    );
    ///////////////////////////////////zh////////////////////////////////////////
    urlencoded.append(
        "translation[zh][description]",
        this.state.zh.description
    );
    ///////////////////////////////////zh_cn////////////////////////////////////////
    urlencoded.append(
        "translation[zh_cn][description]",
        this.state.zh_cn.description
    );

    urlencoded.append("broker[name]", this.state.sysname);
    urlencoded.append("broker[sysname]", this.state.company);

    const img = this.state.logo && this.state.logo.split(";base64,").pop();
    urlencoded.append("broker[logo]", img);

    urlencoded.append("broker[averageRate]", "dddddddddd");
    urlencoded.append("broker[founded]", this.state.founded);
    urlencoded.append("broker[brokerType]", "dfgfg");
    urlencoded.append("broker[maxLeverage]", this.state.maxLeverage);
    urlencoded.append("broker[timeZone]", "dfgfhg");
    urlencoded.append("broker[usTradersAccepted]", "dfhgfhg");
    urlencoded.append("broker[currencyPairsAmount]", this.state.currencyPairsAmount);
    urlencoded.append("broker[bonusProgram]", this.state.bonusProgram);
    urlencoded.append("broker[tradingContests]", this.state.tradingContests);
    urlencoded.append("broker[freeEducation]", this.state.freeEducation);
    urlencoded.append("broker[personalManager]", this.state.personalManager);
    urlencoded.append(
        "broker[islamicAccountsAvailable]",
        this.state.islamicAccountsAvailable
    );

    // state.currencies.split(",").forEach((currencies, index) => {
    //   urlencoded.append("currencies[" + index + "]", currencies);
    // });
    urlencoded.append("broker[tradingByPhone]", this.state.tradingByPhone);
    urlencoded.append("broker[affiliateProgram]", this.state.affiliateProgram);
    urlencoded.append("broker[freeAnalytics]", this.state.freeAnalytics);
    urlencoded.append(
        "broker[wideChoiceInstruments]",
        this.state.wideChoiceInstruments
    );
    urlencoded.append("broker[freeMarginInterest]", this.state.freeMarginInterest);
    urlencoded.append("broker[internalUrl]", this.state.internalUrl);
    urlencoded.append("broker[externalUrl]", this.state.externalUrl);
    urlencoded.append("broker[minSpread]", this.state.minSpread);
    urlencoded.append("broker[minDeposit]", this.state.minDeposit);
    // urlencoded.append("headquartersId[0]", "1");
    this.state.headquartersSelectedOption.forEach((headquarter, index) => {
      urlencoded.append("headquartersId[" + index + "]", headquarter.value);
    });
    this.state.paymentsSelectedOption.forEach((payment, index) => {
      urlencoded.append("paymentOptionsId[" + index + "]", payment.value);
    });
    console.log(this.state.demoPlatform, "[[[[[[[[[demoPlatform")
    this.state.demoPlatform.split(",").forEach((payment, index) => {
      urlencoded.append("demoPlatforms[" + index + "]", payment);
    });
    console.log(this.state.regulatedBy, "[[[[[[[[[regulatedBy")
    this.state.regulatedBy.split(",").forEach((payment, index) => {
      urlencoded.append("regulatedBy[" + index + "]", payment);
    });
    // urlencoded.append("regulatedBy[0][sysname]", "fhghg");
    urlencoded.append("tradingPlatformsId[0]", "1");
    urlencoded.append("tradingInstruments[0][sysname]", "fffff");
    urlencoded.append("customerSupportVia[0][sysname]", "ddd");
    urlencoded.append("spreadTypes[0][sysname]", "fixed");
    urlencoded.append("promotionsId[0]", "1");
    urlencoded.append("websiteLang[0][shortCode]", "dfgfg");
    urlencoded.append("websiteLang[0][name]", "fdgfgdg");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(
        process.env.REACT_APP_API_URL + `${ADMIN_BROKER}/${this.state.currentBrokerId}`,
        requestOptions
    )
        .then(response => {
          response.text();
          this.setState({ responseStatus: response.status });
        })
        // .then(result => console.log(result))
        .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  componentDidMount() {
    this.props.getHeadquartersFullList();
    this.props.getPaymentOptionsFullList();
    this.getBrokerData();
    this.props.toggleMenuTab(1);
  }

  // componentWillUnmount() {
  //   this.props.setBrokerSysname("");
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (this.state.currentLanguage !== prevState.currentLanguage) {
  //         this.getBrokerData();        }
  // }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        logo: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    console.log(this.state, "rrrrrrer")
    let { logo } = this.state;
    let $imagePreview = null;
    if (logo) {
      $imagePreview = (
        <div className="imgPreview">
          <img src={logo} alt={"logo"} />
        </div>
      );
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }

    const headquartersOptions =
      this.props.headquartersFullList &&
      this.props.headquartersFullList.list.length &&
      this.props.headquartersFullList.list.map(item => {
        return { value: `${item.id}`, label: `${item.sysname}` };
      });
    const paymentsOptions =
      this.props.paymentsFullList &&
      this.props.paymentsFullList.list.length &&
      this.props.paymentsFullList.list.map(item => {
        return { value: `${item.id}`, label: `${item.sysname}` };
      });

    const { currentLanguage } = this.state;
    const {
      bonusProgram,
      tradingContests,
      freeEducation,
      personalManager,
      islamicAccountsAvailable,
      tradingByPhone,
      affiliateProgram,
      freeAnalytics,
      wideChoiceInstruments,
      freeMarginInterest
    } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ForexGrad adminpanel | Brokers | Edit "{this.state.sysname}" broker
          </title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              <div>
                {PLATFORM_LANGUAGES.map((language, index) => {
                  return (
                    <button
                      className={`btn ${
                        currentLanguage === language ? "activeBtn" : "pasivBtn"
                      }`}
                      onClick={() => {
                        this.setState(
                          {
                            currentLanguage: language
                          },
                          () => {
                            // this.saveBeforeChangedLanguage();
                            API.getBrokerBySysname(
                              this.props.match.params.sysname,
                              this.state.currentLanguage
                            )
                              .then(res => res.json())
                              .then(data => {
                                this.setState({
                                  headquarters: data.headquarters,
                                  headquartersValue: "",
                                  paymentOptions: data.paymentOptions,
                                  paymentOptionsValue: "",
                                  bonusProgram: +data.advantages[0]
                                    .bonusProgram,
                                  tradingContests: +data.advantages[1]
                                    .tradingContests,
                                  freeEducation: +data.advantages[2]
                                    .freeEducation,
                                  personalManager: +data.advantages[3]
                                    .personalManager,
                                  islamicAccountsAvailable: +data.advantages[4]
                                    .islamicAccountsAvailable,
                                  tradingByPhone: +data.advantages[5]
                                    .tradingByPhone,
                                  affiliateProgram: +data.advantages[6]
                                    .affiliateProgram,
                                  freeAnalytics: +data.advantages[7]
                                    .freeAnalytics,
                                  wideChoiceInstruments: +data.advantages[8]
                                    .wideChoiceInstruments,
                                  freeMarginInterest: +data.advantages[9]
                                    .freeMarginInterest,
                                  founded: data.founded,
                                  demoPlatform: data.demoPlatform,
                                  currencies: data.currencies,
                                  maxLeverage: +data.maxLeverage,
                                  currencyPairsAmount: data.currencyPairsAmount,
                                  responseStatus: null,
                                  sysname: data.sysname,
                                  headquartersSelectedOption: data.headquarters,
                                  paymentsSelectedOption: data.paymentOptions,
                                  isPaymentOptionModalOpened: false,
                                  averageRate: data.averageRate,
                                  regulatedBy: data.regulatedBy,
                                  company: this.props.match.params.sysname,
                                  [this.state.currentLanguage]: {
                                    ...this.state[this.state.currentLanguage],
                                    description: data.description,
                                    internalUrl: data.internalUrl,
                                    externalUrl: data.externalUrl,
                                    minSpread: data.minSpread,
                                    minDeposit: data.minDeposit
                                  }
                                });
                              });
                          }
                        );
                      }}
                    >
                      {language}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <h1>{this.state.sysname}</h1>
            </div>
            <div className="col-12 previewComponent">
              <div className="row flex-column">
                <div className="col-lg-6 mb-4">
                  <input
                    className="fileInput"
                    type="file"
                    onChange={e => this._handleImageChange(e)}
                  />
                </div>
                <div className="col-lg-6 mb-4">{$imagePreview}</div>
              </div>
            </div>

            {PLATFORM_DETAILS_INPUTS.map(input => {
              const InputTag = input.type;
              const { type, ...inputAttrs } = input;
              return (
                <div className="col-12 mb-4">
                  <InputTag
                    className="w-100"
                    {...inputAttrs}
                    onChange={this.handlePlatformInputsChange.bind(this)}
                    value={this.state[currentLanguage].description}
                  />
                </div>
              );
            })}

            <div className="col-12">
              <div className="row">
                <div className="col-xl-9 mb-4 rating_info">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Company</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={this.state.company}
                        onChange={e => {
                          this.setState({ company: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Founded in</span>
                    <span className="d-flex">
                      <input
                        type="number"
                        value={this.state.founded}
                        onChange={e => {
                          this.setState({ founded: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Demo Platforms</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={this.state.demoPlatform}
                        placeholder="MT4, MT5, NTX ..."
                        title="If there are several, separate them with commas!"
                        onChange={e => {
                          this.setState({ demoPlatform: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  {/*<div>*/}
                  {/*  <span>Account Currencies</span>*/}
                  {/*  <span className="text-overflow-60">*/}
                  {/*    <input*/}
                  {/*      type="text"*/}
                  {/*      value={this.state.currencies}*/}
                  {/*      placeholder="USD, EUR, GBP ..."*/}
                  {/*      title="If there are several, separate them with commas!"*/}
                  {/*      onChange={e => {*/}
                  {/*        this.setState({ currencies: e.target.value });*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </span>*/}
                  {/*</div>*/}
                  <div className="d-flex justify-content-between mb-2">
                    <span>Maximum Leverage</span>
                    <span className="d-flex">
                      <div className="d-flex align-items-center">1:</div>
                      <input
                        type="number"
                        value={this.state.maxLeverage}
                        placeholder="222"
                        onChange={e => {
                          this.setState({ maxLeverage: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Currency Pairs</span>
                    <span className="d-flex">
                      <input
                        type="number"
                        value={this.state.currencyPairsAmount}
                        onChange={e => {
                          this.setState({
                            currencyPairsAmount: e.target.value
                          });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Regulated by</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={this.state.regulatedBy}
                        placeholder="FCA (UK), CFTC (Commodity Futures Trading Commission), ASIC (Australia), ..."
                        title="If there are several, separate them with commas!"
                        onChange={e => {
                          this.setState({ regulatedBy: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>External Url</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={this.state.externalUrl}
                        onChange={e => {
                          this.setState({ externalUrl: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Internal Url</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={this.state.internalUrl}
                        onChange={e => {
                          this.setState({ internalUrl: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Min. spread</span>
                    <span className="d-flex">
                      <input
                        type="number"
                        value={this.state.minSpread}
                        onChange={e => {
                          this.setState({ minSpread: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Min. deposit</span>
                    <span className="d-flex">
                      <div className="d-flex align-items-center font-weight-bold">{`$ `}</div>
                      <input
                        type="number"
                        value={this.state.minDeposit}
                        onChange={e => {
                          this.setState({ minDeposit: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 mb-4">
                  <div className="offers">
                    <ul className="ul-tick">
                      <li
                        className={`there_is_${bonusProgram}`}
                        onClick={() =>
                          this.setState({ bonusProgram: +!bonusProgram })
                        }
                      >
                        Bonus offers
                      </li>
                      <li
                        className={`there_is_${tradingContests}`}
                        onClick={() =>
                          this.setState({ tradingContests: +!tradingContests })
                        }
                      >
                        Trading contests
                      </li>
                      <li
                        className={`there_is_${freeEducation}`}
                        onClick={() =>
                          this.setState({ freeEducation: +!freeEducation })
                        }
                      >
                        Free education
                      </li>
                      <li
                        className={`there_is_${personalManager}`}
                        onClick={() =>
                          this.setState({ personalManager: +!personalManager })
                        }
                      >
                        Personal manager
                      </li>
                      <li
                        className={`there_is_${islamicAccountsAvailable}`}
                        onClick={() =>
                          this.setState({
                            islamicAccountsAvailable: +!islamicAccountsAvailable
                          })
                        }
                      >
                        Islamic accounts available
                      </li>
                      <li
                        className={`there_is_${tradingByPhone}`}
                        onClick={() =>
                          this.setState({ tradingByPhone: +!tradingByPhone })
                        }
                      >
                        Trading by telephone
                      </li>
                      <li
                        className={`there_is_${affiliateProgram}`}
                        onClick={() =>
                          this.setState({
                            affiliateProgram: +!affiliateProgram
                          })
                        }
                      >
                        Affiliate program
                      </li>
                      <li
                        className={`there_is_${freeAnalytics}`}
                        onClick={() =>
                          this.setState({ freeAnalytics: +!freeAnalytics })
                        }
                      >
                        Free analytics
                      </li>
                      <li
                        className={`there_is_${wideChoiceInstruments}`}
                        onClick={() =>
                          this.setState({
                            wideChoiceInstruments: +!wideChoiceInstruments
                          })
                        }
                      >
                        Wide Choice Instruments
                      </li>
                      <li
                        className={`there_is_${freeMarginInterest}`}
                        onClick={() =>
                          this.setState({
                            freeMarginInterest: +!freeMarginInterest
                          })
                        }
                      >
                        Free Margin Interest
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row justify-content-between">
                <div className="col-md-6 d-flex flex-column-reverse justify-content-end mb-4">
                  <div className={"Headquarters"}>
                    <span>Headquarters</span>
                    <span>
                      <span className={"select"}>
                        <Select
                          value={this.state.headquartersSelectedOption}
                          onChange={this.handleChangeHeadquarters}
                          options={headquartersOptions}
                          isMulti
                          isOpen={true}
                        />
                      </span>
                    </span>
                  </div>
                  <span className="mb-2">
                    {/*<button*/}
                    {/*    className="btn btn-dark btn-lg w-100"*/}
                    {/*    onClick={() => {*/}
                    {/*        this.setState({isHeadquartersModalOpened: true}, () => this.props.setBrokerSysname(this.props.match.params.sysname));*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  Managed Headquarters*/}
                    {/*</button>*/}

                    <button
                      className="btn btn-dark btn-lg w-100"
                      onClick={() => {
                        this.props.setBrokerSysname(
                          this.props.match.params.sysname
                        );
                        this.props.history.push(BROKER_HEADQUARTERS);
                      }}
                    >
                      Managed Headquarters
                    </button>
                  </span>
                </div>
                <div className="col-md-6 d-flex flex-column-reverse justify-content-end mb-4">
                  <div>
                    <span>Payment Options</span>
                    <span>
                      <span className={"select"} style={{ width: "100%" }}>
                        <Select
                          className={"aaa"}
                          value={this.state.paymentsSelectedOption}
                          onChange={this.handleChangePayments}
                          options={paymentsOptions}
                          isMulti
                          isOpen={true}
                        />
                      </span>
                    </span>
                  </div>

                  <span className="mb-2">
                    {/*<button*/}
                    {/*    className="btn btn-dark btn-lg w-100"*/}
                    {/*    onClick={() => {*/}
                    {/*        this.setState({isPaymentOptionModalOpened: true});*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  Managed Payment Options*/}
                    {/*</button>*/}

                    <button
                      className="btn btn-dark btn-lg w-100"
                      onClick={() => {
                        this.props.setBrokerSysname(
                            this.props.match.params.sysname
                        );
                        this.props.history.push(PAYMENT_OPTIONS);
                      }}
                    >
                      Managed Payment Options
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <button
                className="btn btn-dark btn-lg w-100"
                onClick={() => {
                  this.props.changedBrokerId(this.props.match.params.sysname);
                  this.props.setBrokerSysname(this.props.match.params.sysname);
                  this.props.history.push(ACCOUNT_TYPES);
                  this.props.history.push(
                    ACCOUNT_TYPES_BY_ID(this.props.match.params.sysname)
                  );
                }}
              >
                Managed ACCOUNT TYPES
              </button>
            </div>
            <div className="col-12 mb-4">
              <div className="row">
                <fieldset className="col-12 mb-4">
                  <legend>Account types</legend>
                  <div className="d-flex flex-wrap">
                    {this.state.accountTypes.length &&
                      this.state.accountTypes.map((item, index) => {
                        return (
                          <span key={index}>
                            {item.accountType}({item.tradingPlatform})
                          </span>
                        );
                      })}
                  </div>
                </fieldset>
                <div className="col-12">
                  <Button
                    variant="success"
                    size="lg"
                    block
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    UPDATE BROKER
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentOptionModal
          show={this.state.isPaymentOptionModalOpened}
          onHide={() => {
            this.setState({ isPaymentOptionModalOpened: false });
          }}
          toggleHeadquartersModal={this.togglePaymentOptionsModal}
        />
        <HeadquartersModal
          show={this.state.isHeadquartersModalOpened}
          onHide={() => {
            this.setState({ isHeadquartersModalOpened: false });
          }}
          toggleHeadquartersModal={this.toggleHeadquartersModal}
        />
        <MyVerticallyCenteredModal
          show={this.state.open}
          onHide={() => {
            this.setState({ open: false });
          }}
          onsend={this.sendRequest}
          isSuccessModalOpened={this.state.isSuccessModalOpened}
          closemodal={this.close}
          item={this.state.item}
          responseStatus={this.state.responseStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    // headquartersList: store.headquartersList,
    headquartersFullList: store.headquartersFullList,
    // paymentsList: store.paymentsList,
    paymentsFullList: store.paymentsFullList,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // attemptGetHeadquartersList: (lang, countPerPage, page) =>
    //     dispatch(attemptGetHeadquartersList(lang, countPerPage, page)),
    getHeadquartersFullList: () => dispatch(getHeadquartersFullList()),
    // attemptGetPaymentOptionsList: (lang, countPerPage, page) =>
    //     dispatch(attemptGetPaymentOptionsList(lang, countPerPage, page)),
    getPaymentOptionsFullList: () => dispatch(getPaymentOptionsFullList()),
    changedBrokerId: id => dispatch(changedBrokerId(id)),
    setBrokerSysname: currentBrokerSysname =>
      dispatch(setBrokerSysname(currentBrokerSysname)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateBroker));
