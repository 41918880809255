import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { USER_UPDATE_PAGE, ADD_USERS, LOGIN } from "../../configs/constants";
import {
  attemptGetUsersList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "user",
      countPerPage: 10
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetUsersList(this.state.countPerPage, pageNumber);
    });
  }

  componentDidMount() {
    this.props.attemptGetUsersList(this.state.countPerPage, this.state.activePage);
    this.props.toggleMenuTab(5);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.countPerPage !== prevState.countPerPage ||
      this.state.page !== prevState.page
    ) {
      this.props.attemptGetUsersList(this.state.countPerPage, this.state.page);
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteExpert = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/user/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetUsersList(this.state.countPerPage, 1);
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  render() {
    const { users } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Users</title>
        </Helmet>
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.list &&
                users.list.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>
                        <Link to={USER_UPDATE_PAGE(item.id)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        {/*<div onClick={() => this.deleteExpert(item.expertId)}>*/}
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {users && users.count > this.state.countPerPage ? (
            <div className="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={users.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}

          <button className="btn btn-light mb-5">
            <Link className={"text-decoration-none"} to={ADD_USERS}>
              <FontAwesomeIcon icon={faUserPlus} /> Add new user
            </Link>
          </button>
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteExpert(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    users: store.users
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetUsersList: (countPerPage, page) =>
      dispatch(attemptGetUsersList(countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
