import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { toggleMenuTab } from "../../redux/actions/indexActions";
import { USERS } from "../../configs/constants";
class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      repeatPassword: "",

      open: false,
      secondModal: false,
      passAlert: false,
      mailAlert: false,
      item: "User"
    };
  }

  checkedPasswords = () => {
    if (this.state.repeatPassword !== this.state.password) {
      this.setState({ passAlert: true });
    }
  };

  checkedEmailValidation = () => {
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ mailAlert: true });
    }
  };

  close = () => {
    this.setState(
      {
        email: "",
        firstName: "",
        lastName: "",
        password: "",

        responseStatus: null,

        open: false,
        isSuccessModalOpened: false
      },
      () => this.props.history.push(USERS)
    );
  };

  sendRequest = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", this.state.email);
    urlencoded.append("firstName", this.state.firstName);
    urlencoded.append("lastName", this.state.lastName);
    urlencoded.append("password", this.state.password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_API_URL + "/admin/user", requestOptions)
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      .then(result => console.log(result))
      .catch(error => console.log("error", error));
    this.setState({ isSuccessModalOpened: true });
  };

  componentDidMount() {
    this.props.toggleMenuTab(5);
  }

  render() {
    console.log(this.state.mailAlert, "looooogmailAlert");
    console.log(this.state.passAlert, "looooogpassAlert");
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Users | Create new user</title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              <h1>Add new user</h1>
            </div>

            <div className="col-12 rating_info mb-4">
              <div className="d-flex justify-content-between mb-2">
                <span>First name</span>
                <span className="d-flex flex-row-reverse">
                  <input
                    type="text"
                    value={this.state.firstName}
                    onChange={e => {
                      this.setState({ firstName: e.target.value });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Last name</span>
                <span className="d-flex flex-row-reverse">
                  <input
                    type="text"
                    value={this.state.lastName}
                    onChange={e => {
                      this.setState({ lastName: e.target.value });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Email</span>
                <span className="d-flex flex-row-reverse">
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={e => {
                      this.setState({
                        email: e.target.value,
                        mailAlert: false
                      });
                    }}
                  />
                  {this.state.mailAlert ? (
                    <div className="wrongpass">
                      invalid mail,please type currect mail!!!
                    </div>
                  ) : null}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Password</span>
                <span className="d-flex flex-row-reverse">
                  <input
                    type="text"
                    value={this.state.password}
                    onChange={e => {
                      this.setState({
                        password: e.target.value,
                        passAlert: false
                      });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Repeat Password*</span>
                <span className="d-flex flex-row-reverse">
                  <input
                    type="text"
                    value={this.state.repeatPassword}
                    onChange={e => {
                      this.setState({
                        repeatPassword: e.target.value,
                        passAlert: false
                      });
                    }}
                  />
                  {this.state.passAlert ? (
                    <div className="wrongpass">Password wrong!!!</div>
                  ) : null}
                </span>
              </div>
            </div>
            <div className="col-12">
              <Button
                variant="primary"
                size="lg"
                disabled={
                  !this.state.firstName ||
                  !this.state.lastName ||
                  !this.state.email ||
                  !this.state.repeatPassword ||
                  !this.state.password
                }
                onClick={() => {
                  this.checkedPasswords();
                  this.checkedEmailValidation();
                  if (
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                      this.state.email
                    ) &&
                    this.state.repeatPassword === this.state.password
                  ) {
                    this.setState({ open: true });
                  }
                }}
              >
                ADD NEW USER
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(null, mapDispatchToProps)(AddUser);
