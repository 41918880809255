import React from "react";
import MyVerticallyCenteredModal from "../../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ACCOUNT_TYPES_BY_ID } from "../../../configs/constants";
import Select from "react-select";
import GeneralApi from "../../../api";
import { editAccountTypeDataForRequest } from "./accountTypesUtil";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { toggleMenuTab } from "../../../redux/actions/indexActions";

const API = new GeneralApi();

class UpdateBrokerAccountType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: "",
      minPositionSize: "",
      minAccountSize: "",
      minFixedSpread: "",
      isScalping: 0,
      isExpertAdvisors: 0,
      minFloatSpread: "",
      leverage: "",
      stopOut: "",

      open: false,
      secondModal: false,

      item: "User",
      TradingInstrumentsSelectedOption: [],
      TradingPlatformsSelectedOption: [],
      currenciesSelectedOption: [],
      tradingInstruments: [],
      tradingPlatforms: [],
      currencies: [],
      floating: 0,
      fixed: 0,
      platformArray: [],
      spreadIds: [],
      platformId: null
    };
  }

  attemptGetTradingInstruments = () => {
    fetch(process.env.REACT_APP_API_URL + `/instrument/list`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ tradingInstruments: data });
      })
      .catch(err => console.log(err));
  };
  attemptGetTradingPlatforms = () => {
    fetch(process.env.REACT_APP_API_URL + `/trading-platform?lang=en`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ tradingPlatforms: data });
      })
      .catch(err => console.log(err));
  };
  attemptGetCurrencies = () => {
    fetch(process.env.REACT_APP_API_URL + `/currencies`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ currencies: data });
      })
      .catch(err => console.log(err));
  };

  getBrokerAccountTypeData = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        `/admin/account-type/${this.props.match.params.id}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({
          accountType: data.accountType,
          minPositionSize: data.minPositionSize,
          minAccountSize: data.minAccountSize,
          isScalping: data.isScalping,
          isExpertAdvisors: data.isExpertAdvisors,
          minFloatSpread: data.minFloatSpread,
          leverage: data.leverage,
          stopOut: data.stopOut,
          floating: 0,
          fixed: 0,
          TradingInstrumentsSelectedOption: data.instrumentIds,
          currenciesSelectedOption: data.currencyIds,
          TradingPlatformsSelectedOption: data.platform,
          minFixedSpread: data.minFixedSpread,
          spreadIds: data.spreadIds,
          platformId: data.platformId
        });
      })
      .catch(err => console.log(err));
  };

  handleChangeTradingInstruments = TradingInstrumentsSelectedOption => {
    this.setState({ TradingInstrumentsSelectedOption });
  };
  handleChangespreadIds = spreadIds => {
    this.setState({ spreadIds });
  };
  handleChangeTradingPlatforms = TradingPlatformsSelectedOption => {
    this.setState({ TradingPlatformsSelectedOption });
  };
  handleChangeCurrencies = currenciesSelectedOption => {
    this.setState({ currenciesSelectedOption });
  };

  componentDidMount() {
    this.attemptGetTradingInstruments();
    this.attemptGetTradingPlatforms();
    this.attemptGetCurrencies();
    this.getBrokerAccountTypeData();
  }

  close = () => {
    this.setState(
      {
        responseStatus: null,
        open: false,
        isSuccessModalOpened: false
      },
      () =>
        this.props.history.push(
          ACCOUNT_TYPES_BY_ID(this.props.match.params.sysname)
        )
    );
  };

  sendRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
        "Authorization",
        `token ${localStorage.getItem("accessToken")}`
    );
    const urlencoded = new URLSearchParams();

    urlencoded.append("accountTypes[accountType]", this.state.accountType);
    urlencoded.append("accountTypes[accountId]", this.props.match.params.id);
    urlencoded.append("accountTypes[minAccountSize]", this.state.minAccountSize);
    urlencoded.append("accountTypes[minPositionSize]", this.state.minPositionSize);
    urlencoded.append(
        "accountTypes[minFixedSpread]",
        this.state.spreadIds &&
        this.state.spreadIds.length &&
        this.state.spreadIds.filter(e => e.label === "Fixed").length
            ? this.state.minFixedSpread
            : null
    );
    urlencoded.append(
        "accountTypes[minFloatSpread]",
        this.state.spreadIds &&
        this.state.spreadIds.length &&
        this.state.spreadIds.filter(e => e.label === "Floating").length
            ? this.state.minFloatSpread
            : null
    );
    urlencoded.append("accountTypes[isScalping]", this.state.isScalping);
    urlencoded.append("accountTypes[isExpertAdvisors]", this.state.isExpertAdvisors);
    urlencoded.append("accountTypes[leverage]", `1:${this.state.leverage}`);
    urlencoded.append("accountTypes[stopOut]", "re");

    this.state.TradingInstrumentsSelectedOption.forEach((instrument, index) => {
      urlencoded.append(
          "accountTypes[tradingInstrumentsId][" + index + "]",
          instrument.value
      );
    });
    this.state.spreadIds.forEach((spread, index) => {
      urlencoded.append(
          "accountTypes[spreadTypeId][" + index + "]",
          spread.value
      );
    });
    // state.TradingPlatformsSelectedOption.forEach((platform, index) => {
    urlencoded.append(
        "accountTypes[tradingPlatformId]",
        this.state.TradingPlatformsSelectedOption.value
    );
    // });
    this.state.currenciesSelectedOption.forEach((currency, index) => {
      urlencoded.append(
          "accountTypes[currenciesId][" + index + "]",
          currency.value
      );
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_API_URL +
        `/admin/broker/account-type/update/${this.props.match.params.sysname}`, requestOptions)
        .then(response => {
          response.text();
          this.setState({ responseStatus: response.status });
        })
        // .then(result => console.log(result))
        .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };


  render() {
    const {
      minFixedSpread,
      minFloatSpread,
      accountType,
      minPositionSize,
      minAccountSize,
      isScalping,
      isExpertAdvisors,
      TradingInstrumentsSelectedOption,
      tradingInstruments,
      TradingPlatformsSelectedOption,
      tradingPlatforms,
      currenciesSelectedOption,
      currencies
    } = this.state;


    console.log(this.props.match.params,"this.props.match.params")
    const tradingInstrumentsOptions =
      tradingInstruments &&
      tradingInstruments.length &&
      tradingInstruments.map(item => {
        return { value: `${item.id}`, label: `${item.tradingInstrument}` };
      });
    const tradingPlatformsOptions =
      tradingPlatforms &&
      tradingPlatforms.list &&
      tradingPlatforms.list.length &&
      tradingPlatforms.list.map(item => {
        return { value: `${item.id}`, label: `${item.sysname}` };
      });
    const currencyList =
      currencies &&
      currencies.length &&
      currencies.map(item => {
        return { value: `${item.id}`, label: `${item.name}` };
      });

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ForexGrad adminpanel | Account types | Edit "{accountType}" account
            type
          </title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12 mb-4">
              <h1>Add new account type</h1>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-6 rating_info mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Account Type</span>
                    <span className="d-flex">
                      <input
                        type="text"
                        value={accountType}
                        onChange={e => {
                          this.setState({ accountType: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Minimum account size</span>
                    <span className="d-flex">
                      <input
                        type="number"
                        value={minAccountSize}
                        placeholder={"minAccountSize"}
                        onChange={e => {
                          this.setState({ minAccountSize: e.target.value });
                        }}
                      />
                    </span>
                  </div>
                  {this.state.spreadIds &&
                  this.state.spreadIds.length &&
                  this.state.spreadIds.filter(e => e.label === "Fixed")
                    .length ? (
                    <div>
                      <span>Minimum fixed spread</span>
                      <span>
                        <input
                          type="number"
                          value={minFixedSpread}
                          placeholder={"minFixedSpread"}
                          onChange={e => {
                            this.setState({ minFixedSpread: e.target.value });
                          }}
                        />
                      </span>
                    </div>
                  ) : null}
                  {this.state.spreadIds &&
                  this.state.spreadIds.length &&
                  this.state.spreadIds.filter(e => e.label === "Floating")
                    .length ? (
                    <div className="d-flex justify-content-between mb-2">
                      <span>Minimum floating spread</span>
                      <span className="d-flex">
                        <input
                          type="number"
                          value={minFloatSpread}
                          placeholder={"minFloatSpread"}
                          onChange={e => {
                            this.setState({ minFloatSpread: e.target.value });
                          }}
                        />
                      </span>
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-between mb-2">
                    <span>Minimum position size</span>
                    <span className="d-flex flex-row-reverse">
                      <input
                        type="number"
                        value={minPositionSize}
                        placeholder={"minPositionSize"}
                        onChange={e => {
                          this.setState({ minPositionSize: e.target.value });
                        }}
                      />{" "}
                      <div className="d-flex align-items-center">lot</div>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <div className="row">
                    <div className="col-12 mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={isScalping}
                        onChange={() =>
                          this.setState({ isScalping: 1 - isScalping })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Scalping
                      </label>
                    </div>
                    <div className="col-12 mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        checked={isExpertAdvisors}
                        onChange={() =>
                          this.setState({
                            isExpertAdvisors: 1 - isExpertAdvisors
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck2"
                      >
                        Expert advisors
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col-12 mb-2">
                  <Select
                    className={"aaa"}
                    value={this.state.spreadIds}
                    onChange={this.handleChangespreadIds}
                    options={[
                      { value: 1, label: "Fixed" },
                      { value: 2, label: "Floating" }
                    ]}
                    isMulti
                    isOpen={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-sm-4 d-flex flex-column-reverse justify-content-end mb-4">
                  <div className={"Headquarters"}>
                    <span>Trading Instruments</span>
                    <span>
                      <span className={"select"}>
                        <Select
                          className={"aaa"}
                          value={TradingInstrumentsSelectedOption}
                          onChange={this.handleChangeTradingInstruments}
                          options={tradingInstrumentsOptions}
                          isMulti
                          isOpen={true}
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 d-flex flex-column-reverse justify-content-end mb-4">
                  <div>
                    <span>Trading platforms</span>
                    <span>
                      <span className={"select"} style={{ width: "100%" }}>
                        <Select
                          className={"aaa"}
                          value={TradingPlatformsSelectedOption}
                          onChange={this.handleChangeTradingPlatforms}
                          options={tradingPlatformsOptions}
                          isOpen={true}
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 d-flex flex-column-reverse justify-content-end mb-4">
                  <div>
                    <span>Currencies</span>
                    <span>
                      <span className={"select"} style={{ width: "100%" }}>
                        <Select
                          className={"aaa"}
                          value={currenciesSelectedOption}
                          onChange={this.handleChangeCurrencies}
                          options={currencyList}
                          isMulti
                          isOpen={true}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <MyVerticallyCenteredModal
              show={this.state.open}
              onHide={() => {
                this.setState({ open: false });
              }}
              onsend={this.sendRequest}
              isSuccessModalOpened={this.state.isSuccessModalOpened}
              closemodal={this.close}
              item={this.state.item}
              responseStatus={this.state.responseStatus}
            />
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <Button
                    className="w-100"
                    variant="primary"
                    size="lg"
                    blocK
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    ADD NEW ACCOUNT TYPE
                  </Button>
                </div>
                <div className="col-lg-6 mb2">
                  <button
                    className="btn btn-secondary btn-lg w-100"
                    onClick={() =>
                      this.props.history.push(
                        ACCOUNT_TYPES_BY_ID(this.props.match.params.sysname)
                      )
                    }
                  >
                    Back to broker
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentBrokerId: store.currentBrokerId,
    currentBrokerID: store.currentBrokerID,
    currentBrokerSysname: store.currentBrokerSysname
  };
};

function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateBrokerAccountType));
