import React, { Component } from "react";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }
  getUserData = () => {
    const query = {
      email: this.state.email,
      password: this.state.password
    };
    const queryParams = Object.keys(query)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
      })
      .join("&");
    alert(queryParams);
    // fetch("https://127.0.0.1:2083/admin/register", {
    fetch(process.env.REACT_APP_API_URL + "/admin/register", {
      method: "post",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: queryParams
    })
      .then(res => res.json())
      .then(res => {
        alert(res);
        this.setState({ email: res.email, password: res.password });
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <form onSubmit={this.getUserData}>
        <h3>Sign In</h3>

        <div className="form-group">
          <label>Email address</label>
          <input
            className="form-control"
            type="email"
            value={this.state.email}
            placeholder="Enter email"
            onChange={e => this.setState({ email: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            value={this.state.password}
            placeholder="Enter password"
            onChange={e => this.setState({ password: e.target.value })}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          Submit
        </button>
        {/*<p className="forgot-password text-right">*/}
        {/*  Forgot <a href="#">password?</a>*/}
        {/*</p>*/}
      </form>
    );
  }
}

export default Register;
