import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ADD_ARTICLE,
  ADD_BROKER,
  ADD_TRADING_PLATFORM,
  ARTICLES,
  EXPERTS,
  LOGIN,
  REGISTER,
  ARTICLES_UPDATE,
  ADD_EXPERT,
  EXPERT_UPDATE,
  BROKERS,
  USERS,
  ADD_USERS,
  USER_UPDATE,
  UPDATE_BROKER,
  TRADING_PLATFORM,
  TRADING_PLATFORM_UPDATE,
  PROMOTIONS,
  ADD_PROMOTIONS,
  UPDATE_PROMOTIONS,
  ADD_BROKER_ACCOUNT_TYPE,
  UPDATE_BROKER_ACCOUNT_TYPE,
  ACCOUNT_TYPE_BY_ID,
  MAIN_PAGE,
  PAYMENT_OPTIONS,
  ADD_PAYMENT_OPTION,
  UPDATE_PAYMENT_OPTION,
  BROKER_HEADQUARTERS,
  ADD_BROKER_HEADQUARTERS,
  UPDATE_BROKER_HEADQUARTERS
} from "./constants";
import Login from "../components/Login";
import AddBroker from "../components/broker/AddBroker";
import Register from "../components/Register";
import AdminPage from "../components/adminPage";
import AddArticle from "../components/article/AddArticle";
import UpdateArticle from "../components/article/UpdateArticle";
import Articles from "../components/article";
import Experts from "../components/expert";
import AddExpert from "../components/expert/AddExpert";
import UpdateExpert from "../components/expert/UpdateExpert";
import Brokers from "../components/broker";
import AddUser from "../components/user/AddUser";
import UpdateUser from "../components/user/UpdateUser";
import Users from "../components/user";
import Promotions from "../components/promotions";
import UpdatePromotion from "../components/promotions/UpdatePromotion";
import AddPromotion from "../components/promotions/AddPromotion";
import UpdateBroker from "../components/broker/UpdateBroker";
import TradingPlatforms from "../components/tradingPlatform";
import TradingPlatformDetails from "../components/tradingPlatform/TradingPlatformDetails";
import MainLayout from "../MainLayout";
import BrokerAccountTypes from "../components/broker/accountTypes";
import CreateBrokerAccountType from "../components/broker/accountTypes/CreateBrokerAccountType";
import UpdateBrokerAccountType from "../components/broker/accountTypes/UpdateBrokerAccountType";
import PaymentOptions from "./../components/broker/PaymentOptions";
import AddPaymentOption from "../components/broker/PaymentOptions/AddPaymentOption";
import EditPaymentOption from "../components/broker/PaymentOptions/EditPaymentOption";
import HeadQuarters from "../components/broker/HeadQuarters";
import AddHeadquarter from "../components/broker/HeadQuarters/AddHeadquarter";
import EditHeadquarter from "../components/broker/HeadQuarters/EditHeadquarter";
class Routes extends React.Component {
  render() {
    return (
      <MainLayout>
        <Switch>
          <Route exact path={LOGIN} component={Login} />
          <Route exact path={ADD_BROKER} component={AddBroker} />
          <Route exact path={REGISTER} component={Register} />
          <Route exact path={MAIN_PAGE} component={AdminPage} />
          <Route
            exact
            path={ADD_TRADING_PLATFORM}
            component={TradingPlatformDetails}
          />
          <Route exact path={TRADING_PLATFORM} component={TradingPlatforms} />
          <Route exact path={ADD_ARTICLE} component={AddArticle} />
          <Route exact path={ADD_EXPERT} component={AddExpert} />
          <Route exact path={ARTICLES} component={Articles} />
          <Route exact path={EXPERTS} component={Experts} />
          <Route exact path={ARTICLES_UPDATE} component={UpdateArticle} />
          <Route exact path={UPDATE_BROKER} component={UpdateBroker} />
          <Route exact path={EXPERT_UPDATE} component={UpdateExpert} />
          {/*<Route exact path={ACCOUNT_TYPES} component={BrokerAccountTypes} />*/}
          <Route
            exact
            path={TRADING_PLATFORM_UPDATE}
            component={TradingPlatformDetails}
          />
          <Route exact path={BROKERS} component={Brokers} />
          <Route exact path={USERS} component={Users} />
          <Route exact path={PROMOTIONS} component={Promotions} />
          <Route exact path={UPDATE_PROMOTIONS} component={UpdatePromotion} />
          <Route exact path={ADD_PROMOTIONS} component={AddPromotion} />
          <Route exact path={ADD_USERS} component={AddUser} />
          <Route exact path={USER_UPDATE} component={UpdateUser} />
          <Route
            exact
            path={ACCOUNT_TYPE_BY_ID}
            component={BrokerAccountTypes}
          />
          <Route
            exact
            path={ADD_BROKER_ACCOUNT_TYPE}
            component={CreateBrokerAccountType}
          />
          <Route
            exact
            path={UPDATE_BROKER_ACCOUNT_TYPE}
            component={UpdateBrokerAccountType}
          />
          <Route exact path={PAYMENT_OPTIONS} component={PaymentOptions} />
          <Route exact path={ADD_PAYMENT_OPTION} component={AddPaymentOption} />
          <Route
            exact
            path={UPDATE_PAYMENT_OPTION}
            component={EditPaymentOption}
          />
          <Route exact path={BROKER_HEADQUARTERS} component={HeadQuarters} />
          <Route
            exact
            path={ADD_BROKER_HEADQUARTERS}
            component={AddHeadquarter}
          />
          <Route
            exact
            path={UPDATE_BROKER_HEADQUARTERS}
            component={EditHeadquarter}
          />
        </Switch>
      </MainLayout>
    );
  }
}

export default Routes;
