import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { buttons, EXPERTS } from "../../configs/constants";
import { Helmet } from "react-helmet";
import {
  attemptGetExpertList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
class UpdateExpert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: "",
      expertId: null,

      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,

      item: "Expert",
      en: {
        firstName: "",
        lastName: ""
      },
      ru: {
        firstName: "",
        lastName: ""
      },
      es: {
        firstName: "",
        lastName: ""
      },
      pt: {
        firstName: "",
        lastName: ""
      },
      ar: {
        firstName: "",
        lastName: ""
      },
      fa: {
        firstName: "",
        lastName: ""
      },
      tr: {
        firstName: "",
        lastName: ""
      },
      vi: {
        firstName: "",
        lastName: ""
      },
      ja: {
        firstName: "",
        lastName: ""
      },
      ID: {
        firstName: "",
        lastName: ""
      },
      cs: {
        firstName: "",
        lastName: ""
      },
      hi: {
        firstName: "",
        lastName: ""
      },
      de: {
        firstName: "",
        lastName: ""
      },
      it: {
        firstName: "",
        lastName: ""
      },
      ms: {
        firstName: "",
        lastName: ""
      },
      zh: {
        firstName: "",
        lastName: ""
      },
      zh_cn: {
        firstName: "",
        lastName: ""
      },

      langBtnIndex: 1,
      lang: "en",
      logo: ""
    };
  }

  componentDidMount() {
    Object.keys(this.state).forEach(key => {
      if (
        typeof this.state[key] == "object" &&
        key !== "expertId" &&
        key !== "responseStatus"
      ) {
        fetch(
          process.env.REACT_APP_API_URL +
            `/expert/${this.props.match.params.expertId}?lang=${key}`,
          {
            method: "get",
            headers: {
              Authorization: `token ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
          .then(res => res.json())
          .then(data => {
            console.log(
              data,
              "]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]daaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
            );
            this.setState({
              [key]: {
                firstName: data.firstName,
                lastName: data.lastName
              },
              logo: data.picture
            });
          })
          .catch(err => console.log(err));
      }
    });
    this.props.toggleMenuTab(4);
  }
  close = () => {
    this.setState(
      {
        picture: "",
        firstName: "",
        lastName: "",
        expertId: 0,

        open: false,
        isSuccessModalOpened: false
      },
      () => this.props.history.push(EXPERTS)
    );
  };

  sendRequest = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("expertPicture", this.state.logo);
    //////////////////////////////////////// en /////////////////////////////////////
    urlencoded.append("translations[en][firstName]", this.state.en.firstName);
    urlencoded.append("translations[en][lastName]", this.state.en.lastName);
    //////////////////////////////////////// ru /////////////////////////////////////
    urlencoded.append("translations[ru][firstName]", this.state.ru.firstName);
    urlencoded.append("translations[ru][lastName]", this.state.ru.lastName);
    //////////////////////////////////////// es /////////////////////////////////////
    urlencoded.append("translations[es][firstName]", this.state.es.firstName);
    urlencoded.append("translations[es][lastName]", this.state.es.lastName);
    //////////////////////////////////////// pt /////////////////////////////////////
    urlencoded.append("translations[pt][firstName]", this.state.pt.firstName);
    urlencoded.append("translations[pt][lastName]", this.state.pt.lastName);
    //////////////////////////////////////// ar /////////////////////////////////////
    urlencoded.append("translations[ar][firstName]", this.state.ar.firstName);
    urlencoded.append("translations[ar][lastName]", this.state.ar.lastName);
    //////////////////////////////////////// fa /////////////////////////////////////
    urlencoded.append("translations[fa][firstName]", this.state.fa.firstName);
    urlencoded.append("translations[fa][lastName]", this.state.fa.lastName);
    //////////////////////////////////////// tr /////////////////////////////////////
    urlencoded.append("translations[tr][firstName]", this.state.tr.firstName);
    urlencoded.append("translations[tr][lastName]", this.state.tr.lastName);
    //////////////////////////////////////// vi /////////////////////////////////////
    urlencoded.append("translations[vi][firstName]", this.state.vi.firstName);
    urlencoded.append("translations[vi][lastName]", this.state.vi.lastName);
    //////////////////////////////////////// ja /////////////////////////////////////
    urlencoded.append("translations[ja][firstName]", this.state.ja.firstName);
    urlencoded.append("translations[ja][lastName]", this.state.ja.lastName);
    //////////////////////////////////////// ID /////////////////////////////////////
    urlencoded.append("translations[ID][firstName]", this.state.ID.firstName);
    urlencoded.append("translations[ID][lastName]", this.state.ID.lastName);
    //////////////////////////////////////// cs /////////////////////////////////////
    urlencoded.append("translations[cs][firstName]", this.state.cs.firstName);
    urlencoded.append("translations[cs][lastName]", this.state.cs.lastName);
    //////////////////////////////////////// hi /////////////////////////////////////
    urlencoded.append("translations[hi][firstName]", this.state.hi.firstName);
    urlencoded.append("translations[hi][lastName]", this.state.hi.lastName);
    //////////////////////////////////////// de /////////////////////////////////////
    urlencoded.append("translations[de][firstName]", this.state.de.firstName);
    urlencoded.append("translations[de][lastName]", this.state.de.lastName);
    //////////////////////////////////////// it /////////////////////////////////////
    urlencoded.append("translations[it][firstName]", this.state.it.firstName);
    urlencoded.append("translations[it][lastName]", this.state.it.lastName);
    //////////////////////////////////////// ms /////////////////////////////////////
    urlencoded.append("translations[ms][firstName]", this.state.ms.firstName);
    urlencoded.append("translations[ms][lastName]", this.state.ms.lastName);
    //////////////////////////////////////// zh /////////////////////////////////////
    urlencoded.append("translations[zh][firstName]", this.state.zh.firstName);
    urlencoded.append("translations[zh][lastName]", this.state.zh.lastName);
    //////////////////////////////////////// zh_cn /////////////////////////////////////
    urlencoded.append(
      "translations[zh_cn][firstName]",
      this.state.zh_cn.firstName
    );
    urlencoded.append(
      "translations[zh_cn][lastName]",
      this.state.zh_cn.lastName
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(
      process.env.REACT_APP_API_URL +
        `/admin/expert/${this.props.match.params.expertId}`,
      requestOptions
    )
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      .then(result => console.log(result))
      .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        logo: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const { lang } = this.state;
    let { logo } = this.state;
    let $imagePreview = null;
    if (logo) {
      $imagePreview = <img src={logo} alt={"logo"} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ForexGrad adminpanel | Experts | Edit "{this.state[lang].firstName}{" "}
            {this.state[lang].lastName}" expert
          </title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              {buttons.map((item, index) => {
                return (
                  <button
                    className={`btn ${
                      this.state.langBtnIndex === item.index
                        ? "activeBtn"
                        : "pasivBtn"
                    }`}
                    onClick={() => {
                      this.setState({
                        langBtnIndex: item.index,
                        lang: item.lang
                      });
                    }}
                  >
                    {item.lang}
                  </button>
                );
              })}
            </div>

            <div class="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 mb-4">
                  <input
                    className="fileInput"
                    type="file"
                    onChange={e => this._handleImageChange(e)}
                  />
                </div>
                <div className="col-12 mb-4">
                  <div className="imgPreview">{$imagePreview}</div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 rating_info mb-4">
              <div className="d-flex justify-content-between mb-2">
                <span>First name</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].firstName}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          firstName: e.target.value
                        }
                      });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Last name</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].lastName}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          lastName: e.target.value
                        }
                      });
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-12">
              <Button
                variant="primary"
                size="lg"
                disabled={!this.state.en.firstName || !this.state.en.lastName}
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                UPDATE EXPERT
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(null, mapDispatchToProps)(UpdateExpert);
