import React from "react";
import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { isUserLoggedIn } from "../redux/actions/indexActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import { MAIN_PAGE } from "../configs/constants";
export const cookies = new Cookies();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      result: {},
      passAlert: false,
      mailAlert: false,
      isLoggedin: false
    };
  }

  checkedPasswords = () => {
    const { password } = this.state;
    if (!password || password.length === 0 || /^\s*$/.test(password)) {
      this.setState({ passAlert: true });
    }
  };

  checkedEmailValidation = () => {
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ mailAlert: true });
    }
  };

  setUserDataSender = () => {
    // this.props.setUserData(this.state.email, this.state.password)
    const query = {
      email: this.state.email,
      password: this.state.password
    };
    const queryParams = Object.keys(query)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
      })
      .join("&");
    // console.log("process.env.REACT_APP_API_URL = ", process.env.REACT_APP_API_URL);
    fetch(process.env.REACT_APP_API_URL + "/admin/login", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: queryParams
    })
      .then(res => res.json())
      .then(data => {
        cookies.set("refreshToken", data.token.refreshToken);
        localStorage.setItem("accessToken", data.token.accessToken);
        this.props.userLogin();
        this.props.history.push(MAIN_PAGE);
      })
      .catch(err => {
        if (this.state.mailAlert === false && this.state.passAlert === false) {
          this.setState({ isLoggedin: true });
        }
      });
  };

  render() {
    return (
      <div className="container p-4 w-25">
        <h3>Sign In</h3>
        {this.state.isLoggedin ? (
          <div className="wrongpass">Incorrect email address or password</div>
        ) : null}

        <div className="form-group">
          <label>Email address</label>
          <input
            className="form-control"
            type="email"
            value={this.state.email}
            placeholder="Enter email"
            onChange={e => {
              this.setState({
                email: e.target.value,
                mailAlert: false,
                isLoggedin: false
              });
            }}
          />
          {this.state.mailAlert ? (
            <div className="wrongpass">
              invalid mail,please type currect mail!!!
            </div>
          ) : null}
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            value={this.state.password}
            placeholder="Enter password"
            onChange={e =>
              this.setState({
                password: e.target.value,
                passAlert: false,
                isLoggedin: false
              })
            }
          />
          {this.state.passAlert ? (
            <div className="wrongpass">Password wrong!!!</div>
          ) : null}
        </div>
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            this.checkedPasswords();
            this.checkedEmailValidation();
            this.setUserDataSender();
          }}
          // onClick={()=>this.props.history.push("/admin")}
        >
          Login
        </button>
        {/*<p className="forgot-password text-right">*/}
        {/*  Forgot <a href="#">password?</a>*/}
        {/*</p>*/}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    experts: store.experts
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(isUserLoggedIn())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
