import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import {
  ADD_TRADING_PLATFORM,
  LOGIN,
  TRADING_PLATFORM_UPDATE_PAGE
} from "../../configs/constants";
import { attemptGetTradingPlatformsList } from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import Pagination from "react-js-pagination";

class TradingPlatforms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      responseStatus: null,
      item: "trading platform",
      countPerPage: 10
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetTradingPlatformsList(
        this.props.language,
          this.state.countPerPage,
        pageNumber
      );
    });
  }

  componentDidMount() {
    this.props.attemptGetTradingPlatformsList(
      this.props.language,
        this.state.countPerPage,
      this.state.activePage
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.countPerPage !== prevState.countPerPage) {
      this.props.attemptGetTradingPlatformsList(
        this.props.language,
          this.state.countPerPage,
        this.state.page
      );
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteTradingPlatform = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/trading-platform/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetTradingPlatformsList(
          this.props.language,
          5,
          this.state.page
        );
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  render() {
    const { tradingPlatforms } = this.props;
    return (
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {tradingPlatforms &&
              tradingPlatforms.list.length &&
              tradingPlatforms.list.map((item, index) => {
                return (
                  <tr>
                    <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                    <td>{item.sysname}</td>
                    <td>
                      <Link to={TRADING_PLATFORM_UPDATE_PAGE(item.sysname)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </td>
                    <td>
                      <div
                        onClick={() =>
                          this.setState({ open: true, ID: item.id })
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {tradingPlatforms && tradingPlatforms.count > this.state.countPerPage ? (
          <div className={"mb-4"}>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.countPerPage}
              totalItemsCount={tradingPlatforms.count}
              pageRangeDisplayed={this.state.countPerPage}
              onChange={e => this.handlePageChange(e)}
            />
          </div>
        ) : null}

        <button className="btn btn-light mb-5">
          <Link className={"text-decoration-none"} to={ADD_TRADING_PLATFORM}>
            <FontAwesomeIcon icon={faAddressCard} /> Add new trading platform
          </Link>
        </button>
        <MyVerticallyCenteredModal
          show={this.state.open}
          onHide={() => {
            this.setState({ open: false });
          }}
          onsend={() => this.deleteTradingPlatform(this.state.ID)}
          isSuccessModalOpened={this.state.isSuccessModalOpened}
          closemodal={this.close}
          item={this.state.item}
          responseStatus={this.state.responseStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    tradingPlatforms: store.tradingPlatforms,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetTradingPlatformsList: (lang, countPerPage, page) =>
      dispatch(attemptGetTradingPlatformsList(lang, countPerPage, page))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TradingPlatforms));
