export const MAIN_PAGE = "/";
export const LOGIN = "/login";
export const REGISTER = "/admin/register";
export const ADMIN = "/admin";

export const ADMIN_BROKER = "/admin/broker";
export const ADMIN_PAYMENT = "/admin/payment";
export const ADMIN_HEADQUARTER = "/admin/headquarter";
export const ACCOUNT_TYPES = "/admin/broker/account-types";
export const ACCOUNT_TYPES_BY_ID = id => `/admin/broker/${id}/account-types`;
export const ACCOUNT_TYPE_BY_ID = `/admin/broker/:id/account-types`;
export const ADD_BROKER_ACCOUNT_TYPE =
  "/admin/broker/add/account-type/:sysname";
export const ADD_BROKER_ACCOUNT_TYPE_SYSNAME = sysname =>
  `/admin/broker/add/account-type/${sysname}`;
export const UPDATE_BROKER_ACCOUNT_TYPE =
  "/admin/broker/account-type/update/:id/:sysname";
export const UPDATE_ACCOUNT_TYPES_BY_BROKER_ID = (id, sysname) =>
  `/admin/broker/account-type/update/${id}/${sysname}`;

export const PAYMENT_OPTIONS = "/admin/payment_options";
export const ADD_PAYMENT_OPTION = "/admin/payment_options/add";
export const UPDATE_PAYMENT_OPTION = "/admin/payment_options/update/:id";
export const UPDATE_PAYMENT_OPTION_BY_ID = id =>
  `/admin/payment_options/update/${id}`;

export const BROKER_HEADQUARTERS = "/admin/headquartersss";
export const ADD_BROKER_HEADQUARTERS = "/admin/headquartersss/add";
export const UPDATE_BROKER_HEADQUARTERS = "/admin/headquartersss/update/:id";
export const UPDATE_BROKER_HEADQUARTERS_BY_ID = id =>
  `/admin/headquartersss/update/${id}`;

export const BROKERS = "/admin/brokers";
export const ADD_BROKER = "/admin/add-broker";
export const UPDATE_BROKER = `/admin/brokers/update/:sysname`;
export const UPDATE_BROKER_DATA = sysname => `/admin/brokers/update/${sysname}`;

export const ARTICLES = "/admin/articles";
export const ADD_ARTICLE = "/admin/add-article";
export const ARTICLES_UPDATE = "/admin/article/update/:slug";
export const ARTICLE_UPDATE_PAGE = slug => `/admin/article/update/${slug}`;

export const USERS = "/admin/users";
export const ADD_USERS = "/admin/add-users";
export const USER_UPDATE = "/admin/user/update/:id";
export const USER_UPDATE_PAGE = id => `/admin/user/update/${id}`;

export const PROMOTIONS = "/admin/promotions";
export const ADD_PROMOTIONS = "/admin/promotions/add-promotion";
export const UPDATE_PROMOTIONS = "/admin/promotions/update/:id";
export const PROMOTION_UPDATE_PAGE = id => `/admin/promotions/update/${id}`;

export const TRADING_PLATFORM = "/admin/trading-platform";
export const ADD_TRADING_PLATFORM = "/admin/add-trading-platform";
export const TRADING_PLATFORM_UPDATE =
  "/admin/trading-platform/update/:sysname";
export const TRADING_PLATFORM_UPDATE_PAGE = sysname =>
  `/admin/trading-platform/update/${sysname}`;

export const EXPERTS = "/admin/experts";
export const ADD_EXPERT = "/admin/add-experts";
export const EXPERT_UPDATE = "/admin/expert/update/:expertId";
export const EXPERT_UPDATE_PAGE = expertId =>
  `/admin/expert/update/${expertId}`;

export const buttons = [
  {
    lang: "en",
    index: 1
  },
  {
    lang: "ru",
    index: 2
  },
  {
    lang: "es",
    index: 3
  },
  {
    lang: "pt",
    index: 4
  },
  {
    lang: "ar",
    index: 5
  },
  {
    lang: "fa",
    index: 6
  },
  {
    lang: "tr",
    index: 7
  },
  {
    lang: "vi",
    index: 8
  },
  {
    lang: "ja",
    index: 9
  },
  {
    lang: "ID",
    index: 10
  },
  {
    lang: "cs",
    index: 11
  },
  {
    lang: "hi",
    index: 12
  },
  {
    lang: "de",
    index: 13
  },
  {
    lang: "it",
    index: 14
  },
  {
    lang: "ms",
    index: 15
  },
  {
    lang: "zh",
    index: 16
  },
  {
    lang: "zh_cn",
    index: 17
  }
];

export let PLATFORM_DEVICES = [
  {
    device_id: 1,
    isAvailable: false,
    device_name: "Windows",
    download: ""
  },
  {
    device_id: 2,
    isAvailable: false,
    device_name: "Web Terminal",
    download: ""
  },
  {
    device_id: 4,
    isAvailable: false,
    device_name: "iOS",
    download: ""
  },
  {
    device_id: 5,
    device_name: "Android",
    isAvailable: false,
    download: ""
  },
  {
    device_id: 6,
    device_name: "MultiTerminal",
    isAvailable: false,
    download: ""
  }
];
