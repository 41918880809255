import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import { PLATFORM_DEVICES, TRADING_PLATFORM } from "./../../configs/constants";
import GeneralApi from "../../api";
import { parseDataForRequest } from "./util";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { toggleMenuTab } from "../../redux/actions/indexActions";
export const PLATFORM_LANGUAGES = [
  "en",
  "ru",
  "es",
  "pt",
  "ar",
  "fa",
  "tr",
  "vi",
  "ja",
  "ID",
  "cs",
  "hi",
  "de",
  "it",
  "ms",
  "zh",
  "zh_cn"
];

const PLATFORM_DETAILS_INPUTS = [
  {
    name: "name",
    placeholder: "Platform name",
    type: "input"
  },
  {
    cols: "150",
    name: "description",
    rows: "3",
    placeholder: "Broker short description",
    type: "textarea"
  },
  {
    name: "fullDescription",
    cols: "150",
    rows: "5",
    placeholder: "Broker full description",
    type: "textarea"
  }
];

const API = new GeneralApi();

class TradingPlatformDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      en: {
        name: "",
        description: "",
        fullDescription: ""
      },
      ru: {
        name: "",
        description: "",
        fullDescription: ""
      },
      es: {
        name: "",
        description: "",
        fullDescription: ""
      },
      pt: {
        name: "",
        description: "",
        fullDescription: ""
      },
      ar: {
        name: "",
        description: "",
        fullDescription: ""
      },
      fa: {
        name: "",
        description: "",
        fullDescription: ""
      },
      tr: {
        name: "",
        description: "",
        fullDescription: ""
      },
      vi: {
        name: "",
        description: "",
        fullDescription: ""
      },
      ja: {
        name: "",
        description: "",
        fullDescription: ""
      },
      ID: {
        name: "",
        description: "",
        fullDescription: ""
      },
      cs: {
        name: "",
        description: "",
        fullDescription: ""
      },
      hi: {
        name: "",
        description: "",
        fullDescription: ""
      },
      de: {
        name: "",
        description: "",
        fullDescription: ""
      },
      it: {
        name: "",
        description: "",
        fullDescription: ""
      },
      ms: {
        name: "",
        description: "",
        fullDescription: ""
      },
      zh: {
        name: "",
        description: "",
        fullDescription: ""
      },
      zh_cn: {
        name: "",
        description: "",
        fullDescription: ""
      },
      platformDevices: _.cloneDeep(PLATFORM_DEVICES),
      currentLanguage: PLATFORM_LANGUAGES[0],
      modalName: "Trading Platform",
      show: 1,
      logo: "",
      sysname: "",
      responseStatus: null,
      isSuccessModalOpened: false,
      open: false,
      platformDevicesFromApi: [],
      file: "",
      tradingPlatformID: null
      // imagePreviewUrl: ''
    };
  }

  getDefaultLanguagesParams() {
    const languages = {};
    PLATFORM_LANGUAGES.forEach(lang => {
      languages[lang] = {
        name: "",
        description: "",
        fullDescription: ""
      };
    });

    return languages;
  }

  handlePlatformInputsChange({ target }) {
    const { currentLanguage } = this.state;
    const { name, value } = target;
    this.setState({
      [currentLanguage]: {
        ...this.state[currentLanguage],
        [name]: value
      }
    });
  }

  close = () => {
    if (this.state.responseStatus < 400) {
      this.setState(
        {
          sysname: "",
          fullDesc: "",
          shortDesc: "",

          device_id: "",
          download: "",

          platformDevices: [],
          device_name: "",
          isSuccessModalOpened: false
        },
        () => this.props.history.push(TRADING_PLATFORM)
      );
    } else {
      this.setState({ isSuccessModalOpened: false, open: false });
    }
  };

  handleTradingPlatformData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
        "Authorization",
        `token ${localStorage.getItem("accessToken")}`
    );

    const urlencoded = new URLSearchParams();
    urlencoded.append("slug", this.state.slug);
    urlencoded.append("expertId", this.state.expertId);
    urlencoded.append("translations[en][name]", this.state.en.name);
    urlencoded.append(
        "translations[en][description]",
        this.state.en.description
    );
    urlencoded.append("translations[en][fullDescription]", this.state.en.fullDescription);
    urlencoded.append("translations[ru][name]", this.state.ru.name);
    urlencoded.append(
        "translations[ru][description]",
        this.state.ru.description
    );
    urlencoded.append("translations[ru][fullDescription]", this.state.ru.fullDescription);

    ///////////////////////////////////es////////////////////////////////////////
    urlencoded.append("translations[es][name]", this.state.es.name);
    urlencoded.append(
        "translations[es][description]",
        this.state.es.description
    );
    urlencoded.append("translations[es][fullDescription]", this.state.es.fullDescription);
    ///////////////////////////////////pt////////////////////////////////////////
    urlencoded.append("translations[pt][name]", this.state.pt.name);
    urlencoded.append(
        "translations[pt][description]",
        this.state.pt.description
    );
    urlencoded.append("translations[pt][fullDescription]", this.state.pt.fullDescription);
    ///////////////////////////////////ar////////////////////////////////////////
    urlencoded.append("translations[ar][name]", this.state.ar.name);
    urlencoded.append(
        "translations[ar][description]",
        this.state.ar.description
    );
    urlencoded.append("translations[ar][fullDescription]", this.state.ar.fullDescription);
    ///////////////////////////////////fa////////////////////////////////////////
    urlencoded.append("translations[fa][name]", this.state.fa.name);
    urlencoded.append(
        "translations[fa][description]",
        this.state.fa.description
    );
    urlencoded.append("translations[fa][fullDescription]", this.state.fa.fullDescription);
    ///////////////////////////////////tr////////////////////////////////////////
    urlencoded.append("translations[tr][name]", this.state.tr.name);
    urlencoded.append(
        "translations[tr][description]",
        this.state.tr.description
    );
    urlencoded.append("translations[tr][fullDescription]", this.state.tr.fullDescription);
    ///////////////////////////////////vi////////////////////////////////////////
    urlencoded.append("translations[vi][name]", this.state.vi.name);
    urlencoded.append(
        "translations[vi][description]",
        this.state.vi.description
    );
    urlencoded.append("translations[vi][fullDescription]", this.state.vi.fullDescription);
    ///////////////////////////////////ja////////////////////////////////////////
    urlencoded.append("translations[ja][name]", this.state.ja.name);
    urlencoded.append(
        "translations[ja][description]",
        this.state.ja.description
    );
    urlencoded.append("translations[ja][fullDescription]", this.state.ja.fullDescription);
    ///////////////////////////////////ID////////////////////////////////////////
    urlencoded.append("translations[ID][name]", this.state.ID.name);
    urlencoded.append(
        "translations[ID][description]",
        this.state.ID.description
    );
    urlencoded.append("translations[ID][fullDescription]", this.state.ID.fullDescription);
    ///////////////////////////////////cs////////////////////////////////////////
    urlencoded.append("translations[cs][name]", this.state.cs.name);
    urlencoded.append(
        "translations[cs][description]",
        this.state.cs.description
    );
    urlencoded.append("translations[cs][fullDescription]", this.state.cs.fullDescription);
    ///////////////////////////////////hi////////////////////////////////////////
    urlencoded.append("translations[hi][name]", this.state.hi.name);
    urlencoded.append(
        "translations[hi][description]",
        this.state.hi.description
    );
    urlencoded.append("translations[hi][fullDescription]", this.state.hi.fullDescription);
    ///////////////////////////////////de////////////////////////////////////////
    urlencoded.append("translations[de][name]", this.state.de.name);
    urlencoded.append(
        "translations[de][description]",
        this.state.de.description
    );
    urlencoded.append("translations[de][fullDescription]", this.state.de.fullDescription);
    ///////////////////////////////////it////////////////////////////////////////
    urlencoded.append("translations[it][name]", this.state.it.name);
    urlencoded.append(
        "translations[it][description]",
        this.state.it.description
    );
    urlencoded.append("translations[it][fullDescription]", this.state.it.fullDescription);
    ///////////////////////////////////ms////////////////////////////////////////
    urlencoded.append("translations[ms][name]", this.state.ms.name);
    urlencoded.append(
        "translations[ms][description]",
        this.state.ms.description
    );
    urlencoded.append("translations[ms][fullDescription]", this.state.ms.fullDescription);
    ///////////////////////////////////zh////////////////////////////////////////
    urlencoded.append("translations[zh][name]", this.state.zh.name);
    urlencoded.append(
        "translations[zh][description]",
        this.state.zh.description
    );
    urlencoded.append("translations[zh][fullDescription]", this.state.zh.fullDescription);
    ///////////////////////////////////zh_cn////////////////////////////////////////
    urlencoded.append("translations[zh_cn][name]", this.state.zh_cn.name);
    urlencoded.append(
        "translations[zh_cn][description]",
        this.state.zh_cn.description
    );
    urlencoded.append("translations[zh_cn][fullDescription]", this.state.zh_cn.fullDescription);


    const img = this.state.logo && this.state.logo.split(";base64,").pop();

    urlencoded.append("logo", img);
    urlencoded.append("sysname", this.state.sysname);
    urlencoded.append("show", this.state.show);

    this.state.platformDevices.forEach((device, index) => {
      if (!device.isAvailable) {
        return;
      }
      urlencoded.append("device[" + index + "][deviceId]", device.device_id);
      urlencoded.append(
          "device[" + index + "][deviceDownloadLink]",
          device.download
      );
      urlencoded.append("device[" + index + "][device_name]", device.device_name);
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    const {
      location: { pathname }
    } = this.props;

    if (pathname.includes("update")) {
      fetch(
          process.env.REACT_APP_API_URL + `${TRADING_PLATFORM}/${this.state.tradingPlatformID}`,
          requestOptions
      )
          .then(response => {
            response.text();
            this.setState({ responseStatus: response.status });
          })
          // .then(result => console.log(result))
          .catch(error => console.log("error", error));

      this.setState({ isSuccessModalOpened: true });
    } else {
      fetch(process.env.REACT_APP_API_URL + `${TRADING_PLATFORM}`, requestOptions)
          .then(response => {
            response.text();
            this.setState({ responseStatus: response.status });
          })
          // .then(result => console.log(result))
          .catch(error => console.log("error", error));

      this.setState({ isSuccessModalOpened: true });
    }


  }



  handlePlatformDeviceAvailableChanged({ target }) {
    const { platformDevices } = this.state;
    this.setState({
      platformDevices: platformDevices.map(device => {
        if (device.device_name === target.name) {
          device.isAvailable = !device.isAvailable;
        }
        return device;
      })
    });
  }
  handleLanguageChange = language => {
    const {
      location: { pathname }
    } = this.props;
    if (pathname.includes("update")) {
      this.setState(
        {
          currentLanguage: language
        },
        () => {
          API.getPlatformBySysname(
            this.props.match.params.sysname,
            this.state.currentLanguage
          )
            .then(res => res.json())
            .then(data =>
              this.setState({
                // platformDevices: _.cloneDeep(PLATFORM_DEVICES).map(item => {
                //   console.log(PLATFORM_DEVICES, 'PLATFORM_DEVICES');
                //   const device = data.devices.find(device => device.device_name === item.device_name)
                //   console.log(item);
                //   if(device){
                //     return {...item, download: device.download, isAvailable: true}
                //   }
                //   return item
                // }),
                [this.state.currentLanguage]: {
                  ...this.state[this.state.currentLanguage],
                  description: data.description,
                  fullDescription: data.fullDescription,
                  name: data.name
                }
              })
            )
            .catch(err => console.log(err));
        }
      );
    } else {
      this.setState({
        currentLanguage: language,
        platformDevices: _.cloneDeep(PLATFORM_DEVICES)
      });
    }
  };

  handlePlatformDeviceNameChanged({ target }) {
    const { platformDevices } = this.state;
    this.setState({
      platformDevices: platformDevices.map(device => {
        if (device.device_name === target.name) {
          device.download = target.value;
        }
        return device;
      })
    });
  }

  componentDidMount() {
    this.props.toggleMenuTab(3);
    const {
      location: { pathname },
      match: { params }
    } = this.props;
    if (pathname.includes("update")) {
      API.getPlatformBySysname(params.sysname, "en")
        .then(res => res.json())
        .then(data => {
          this.setState({
            show: data.show,
            logo: data.logo,
            tradingPlatformID: data.id,
            sysname: params.sysname,
            platformDevices: _.cloneDeep(PLATFORM_DEVICES).map(item => {
              const device = data.devices.find(
                device => device.device_name === item.device_name
              );
              if (device) {
                return {
                  ...item,
                  download: device.download,
                  isAvailable: true
                };
              }
              return item;
            }),
            platformDevicesFromApi: data.devices,
            [this.state.currentLanguage]: {
              ...this.state[this.state.currentLanguage],
              description: data.description,
              fullDescription: data.fullDescription,
              name: data.name
            }
          });
        })
        .catch(err => console.log(err));
    }
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        logo: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const {
      location: { pathname }
    } = this.props;

    let { logo } = this.state;
    let $imagePreview = null;
    if (logo) {
      $imagePreview = <img src={logo} alt={"logo"} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }

    const { currentLanguage, sysname, platformDevices } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`ForexGrad adminpanel | Trading Platforms | ${
            this.props.location.pathname.includes("update")
              ? `Edit "${this.state.sysname} trading platform"`
              : "Create new trading platform"
          }`}</title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12 mb-4">
              {PLATFORM_LANGUAGES.map((language, index) => {
                return (
                  <button
                    key={index}
                    className={`btn ${
                      currentLanguage === language ? "activeBtn" : "pasivBtn"
                    }`}
                    onClick={() => this.handleLanguageChange(language)}
                  >
                    {language}
                  </button>
                );
              })}
            </div>
            <div className="col-12">
              <h2>
                <strong>Trading platform / </strong>
                <span>
                  {pathname.includes("update") ? (
                    <>{sysname}</>
                  ) : (
                    <input
                      className="p-1"
                      type="text"
                      placeholder="Trading platform name"
                      value={sysname}
                      onChange={({ target }) => {
                        this.setState({ sysname: target.value });
                      }}
                    />
                  )}
                </span>
              </h2>
            </div>

            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 mb-4">
                  <input
                    className="fileInput mb-2"
                    type="file"
                    onChange={e => this._handleImageChange(e)}
                  />
                </div>
                <div className="col-12 mb-4">
                  <div className="imgPreview">{$imagePreview}</div>
                </div>
              </div>
            </div>

            {PLATFORM_DETAILS_INPUTS.map(input => {
              const InputTag = input.type;
              const { type, ...inputAttrs } = input;
              return (
                <div className="col-12 d-flex mb-4">
                  <InputTag
                    className="p-2 w-100"
                    {...inputAttrs}
                    onChange={this.handlePlatformInputsChange.bind(this)}
                    value={this.state[currentLanguage][inputAttrs.name]}
                  />
                </div>
              );
            })}

            <div className="col-12">
              <>
                {platformDevices.map(device => (
                  <div className={"row"}>
                    <input
                      type={"checkbox"}
                      onChange={this.handlePlatformDeviceAvailableChanged.bind(
                        this
                      )}
                      id={device.device_name}
                      name={device.device_name}
                      checked={device.isAvailable}
                    />
                    <label
                      className={"col-4 no-padding"}
                      htmlFor={device.device_name}
                    >
                      <span>{device.device_name}</span>
                    </label>
                    <span className={"col-8"}>
                      <input
                        style={{ width: "100%" }}
                        type="text"
                        name={device.device_name}
                        value={device.isAvailable ? device.download : ""}
                        onChange={this.handlePlatformDeviceNameChanged.bind(
                          this
                        )}
                      />
                    </span>
                  </div>
                ))}
              </>
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  checked={this.state.show}
                  onChange={() => this.setState({ show: 1 - this.state.show })}
                />
                <label htmlFor="vehicle1">SHOW</label>{" "}
              </div>
            </div>
            <div className="col-12">
              <Button
                variant="primary"
                size="lg"
                disabled={!this.state.sysname}
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                {pathname.includes("update")
                  ? "UPDATE TRADING PLATFORM"
                  : "ADD TRADING PLATFORM"}
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.handleTradingPlatformData}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.modalName}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(null, mapDispatchToProps)(TradingPlatformDetails);
