import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./../Success.css";

class DeletePaymentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
      >
        <Modal.Body>
          <h4>Save new {this.props.item}</h4>

          {this.props.isSuccessModalOpened ? (
            <section className="c-container">
              {this.props.responseStatus && this.props.responseStatus > 400 ? (
                <>
                  <div className="o-circle c-container__circle o-circle__sign--failure">
                    <div className="o-circle__sign" />
                  </div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={this.props.closemodal}
                  >
                    try again
                  </button>
                </>
              ) : (
                <>
                  <div className="o-circle c-container__circle o-circle__sign--success">
                    <div className="o-circle__sign" />
                  </div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={this.props.closemodal}
                  >
                    ok
                  </button>
                </>
              )}
            </section>
          ) : (
            <p>Are you sure you want this headquarter</p>
          )}
        </Modal.Body>
        {!this.props.isSuccessModalOpened ? (
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Back</Button>
            <Button onClick={this.props.onsend}>Delete</Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

export default DeletePaymentsModal;
