import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { ADD_BROKER, LOGIN, UPDATE_BROKER_DATA } from "../../configs/constants";
import {
  attemptGetBrokersList,
  isUserLoggedOut,
  setCurrentBrokerId,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";
class Brokers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      item: "Broker",
      page: 1,
      responseStatus: null,
      countPerPage: 9
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetBrokersList(
        this.props.language,
        this.state.countPerPage,
        pageNumber
      );
    });
  }

  componentDidMount() {
    this.props.toggleMenuTab(1);
    this.props.attemptGetBrokersList(
      this.props.language,
      this.state.countPerPage,
      this.state.activePage
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.countPerPage !== prevState.countPerPage) {
      this.props.attemptGetBrokersList(
        this.props.language,
        this.state.countPerPage,
        this.state.page
      );
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deleteBroker = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/broker/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetBrokersList(
          this.props.language,
            this.state.countPerPage,
          this.state.page
        );
        this.setState({
          isSuccessModalOpened: true,
          activePage: 1,
          responseStatus: res.status
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  };

  render() {
    const { brokers } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Brokers</title>
        </Helmet>
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {brokers &&
                brokers.list.length &&
                brokers.list.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{item.name}</td>
                      <td>
                        <Link to={UPDATE_BROKER_DATA(item.sysname)}>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={this.props.setCurrentBrokerId(item.id)}
                          />
                        </Link>
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {brokers && brokers.count > this.state.countPerPage ? (
            <div className="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={brokers.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}
          <button className="btn btn-light mb-4">
            <Link className={"text-decoration-none"} to={ADD_BROKER}>
              <FontAwesomeIcon icon={faAddressCard} /> Add new broker
            </Link>
          </button>
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deleteBroker(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brokers: store.brokers,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokersList: (lang, countPerPage, page) =>
      dispatch(attemptGetBrokersList(lang, countPerPage, page)),
    setCurrentBrokerId: currentBrokerID =>
      dispatch(setCurrentBrokerId(currentBrokerID)),
    userLogOut: () => dispatch(isUserLoggedOut()),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Brokers));
