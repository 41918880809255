import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import {
  attemptGetExpertList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { buttons, PROMOTIONS } from "./../../configs/constants";
import { Helmet } from "react-helmet";

class AddPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sysname: "",
      item: "Promotions",
      en: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      ru: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      es: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      pt: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      ar: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      fa: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      tr: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      vi: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      ja: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      ID: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      cs: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      hi: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      de: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      it: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      ms: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      zh: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },
      zh_cn: {
        link: "",
        shortDescription: "",
        longDescription: ""
      },

      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,
      expertId: null,

      openTransFields: true,

      langBtnIndex: 1,
      lang: "en"
    };
  }

  close = () => {
    this.setState(
      {
        sysname: "",
        link: "",
        shortDescription: "",
        longDescription: "",
        open: false,
        isSuccessModalOpened: false,
        text: ""
      },
      () => this.props.history.push(PROMOTIONS)
    );
  };
  sendRequest = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("sysname", this.state.sysname);

    ///////////////////////////////////en///////////////////////////////////////

    urlencoded.append("translations[en][link]", this.state.en.link);
    urlencoded.append(
      "translations[en][shortDescription]",
      this.state.en.shortDescription
    );
    urlencoded.append(
      "translations[en][longDescription]",
      this.state.en.longDescription
    );

    ///////////////////////////////////ru////////////////////////////////////////

    urlencoded.append("translations[ru][link]", this.state.ru.link);
    urlencoded.append(
      "translations[ru][shortDescription]",
      this.state.ru.shortDescription
    );
    urlencoded.append(
      "translations[ru][longDescription]",
      this.state.ru.longDescription
    );

    ///////////////////////////////////es////////////////////////////////////////

    urlencoded.append("translations[es][link]", this.state.es.link);
    urlencoded.append(
      "translations[es][shortDescription]",
      this.state.es.shortDescription
    );
    urlencoded.append(
      "translations[es][longDescription]",
      this.state.es.longDescription
    );

    ///////////////////////////////////pt////////////////////////////////////////

    urlencoded.append("translations[pt][link]", this.state.pt.link);
    urlencoded.append(
      "translations[pt][shortDescription]",
      this.state.pt.shortDescription
    );
    urlencoded.append(
      "translations[pt][longDescription]",
      this.state.pt.longDescription
    );

    ///////////////////////////////////ar////////////////////////////////////////

    urlencoded.append("translations[ar][link]", this.state.ar.link);
    urlencoded.append(
      "translations[ar][shortDescription]",
      this.state.ar.shortDescription
    );
    urlencoded.append(
      "translations[ar][longDescription]",
      this.state.ar.longDescription
    );

    ///////////////////////////////////fa////////////////////////////////////////

    urlencoded.append("translations[fa][link]", this.state.fa.link);
    urlencoded.append(
      "translations[fa][shortDescription]",
      this.state.fa.shortDescription
    );
    urlencoded.append(
      "translations[fa][longDescription]",
      this.state.fa.longDescription
    );

    ///////////////////////////////////tr////////////////////////////////////////

    urlencoded.append("translations[tr][link]", this.state.tr.link);
    urlencoded.append(
      "translations[tr][shortDescription]",
      this.state.tr.shortDescription
    );
    urlencoded.append(
      "translations[tr][longDescription]",
      this.state.tr.longDescription
    );

    ///////////////////////////////////vi////////////////////////////////////////

    urlencoded.append("translations[vi][link]", this.state.vi.link);
    urlencoded.append(
      "translations[vi][shortDescription]",
      this.state.vi.shortDescription
    );
    urlencoded.append(
      "translations[vi][longDescription]",
      this.state.vi.longDescription
    );

    ///////////////////////////////////ja////////////////////////////////////////

    urlencoded.append("translations[ja][link]", this.state.ja.link);
    urlencoded.append(
      "translations[ja][shortDescription]",
      this.state.ja.shortDescription
    );
    urlencoded.append(
      "translations[ja][longDescription]",
      this.state.ja.longDescription
    );

    ///////////////////////////////////ID////////////////////////////////////////

    urlencoded.append("translations[ID][link]", this.state.ID.link);
    urlencoded.append(
      "translations[ID][shortDescription]",
      this.state.ID.shortDescription
    );
    urlencoded.append(
      "translations[ID][longDescription]",
      this.state.ID.longDescription
    );

    ///////////////////////////////////cs////////////////////////////////////////

    urlencoded.append("translations[cs][link]", this.state.cs.link);
    urlencoded.append(
      "translations[cs][shortDescription]",
      this.state.cs.shortDescription
    );
    urlencoded.append(
      "translations[cs][longDescription]",
      this.state.cs.longDescription
    );

    ///////////////////////////////////hi////////////////////////////////////////

    urlencoded.append("translations[hi][link]", this.state.hi.link);
    urlencoded.append(
      "translations[hi][shortDescription]",
      this.state.hi.shortDescription
    );
    urlencoded.append(
      "translations[hi][longDescription]",
      this.state.hi.longDescription
    );

    ///////////////////////////////////de////////////////////////////////////////

    urlencoded.append("translations[de][link]", this.state.de.link);
    urlencoded.append(
      "translations[de][shortDescription]",
      this.state.de.shortDescription
    );
    urlencoded.append(
      "translations[de][longDescription]",
      this.state.de.longDescription
    );

    ///////////////////////////////////it////////////////////////////////////////

    urlencoded.append("translations[it][link]", this.state.it.link);
    urlencoded.append(
      "translations[it][shortDescription]",
      this.state.it.shortDescription
    );
    urlencoded.append(
      "translations[it][longDescription]",
      this.state.it.longDescription
    );

    ///////////////////////////////////ms////////////////////////////////////////

    urlencoded.append("translations[ms][link]", this.state.ms.link);
    urlencoded.append(
      "translations[ms][shortDescription]",
      this.state.ms.shortDescription
    );
    urlencoded.append(
      "translations[ms][longDescription]",
      this.state.ms.longDescription
    );

    ///////////////////////////////////zh////////////////////////////////////////

    urlencoded.append("translations[zh][link]", this.state.zh.link);
    urlencoded.append(
      "translations[zh][shortDescription]",
      this.state.zh.shortDescription
    );
    urlencoded.append(
      "translations[zh][longDescription]",
      this.state.zh.longDescription
    );

    ///////////////////////////////////zh_cn////////////////////////////////////////

    urlencoded.append("translations[zh_cn][link]", this.state.zh_cn.link);
    urlencoded.append(
      "translations[zh_cn][shortDescription]",
      this.state.zh_cn.shortDescription
    );
    urlencoded.append(
      "translations[zh_cn][longDescription]",
      this.state.zh_cn.longDescription
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    fetch(process.env.REACT_APP_API_URL + "/admin/promotion", requestOptions)
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  componentDidMount() {
    this.props.toggleMenuTab(6);
  }

  render() {
    const { lang } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ForexGrad adminpanel | Promotions | Create new promotion
          </title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              <h1>Add new promotion</h1>
            </div>

            <div className="col-12">
              {buttons.map((item, index) => {
                return (
                  <button
                    className={`btn ${
                      this.state.langBtnIndex === item.index
                        ? "activeBtn"
                        : "pasivBtn"
                    }`}
                    onClick={() => {
                      this.setState({
                        langBtnIndex: item.index,
                        lang: item.lang
                      });
                    }}
                  >
                    {item.lang}
                  </button>
                );
              })}
            </div>

            <div className="col-12 rating_info mb-4">
              <div className="d-flex justify-content-between mb-2">
                <span>Sysname</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state.sysname}
                    onChange={e => {
                      this.setState({
                        sysname: e.target.value
                      });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Link</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].link}
                    onChange={e => {
                      this.setState({
                        [lang]: { ...this.state[lang], link: e.target.value }
                      });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Short Description</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].shortDescription}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          shortDescription: e.target.value
                        }
                      });
                    }}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Long Description</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].longDescription}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          longDescription: e.target.value
                        }
                      });
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-12">
              <Button
                variant="primary"
                size="lg"
                disabled={
                  !this.state.sysname ||
                  !this.state.en.link ||
                  !this.state.en.shortDescription ||
                  !this.state.en.longDescription
                }
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                ADD NEW PROMOTION
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    promotions: store.promotions
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetExpertList: (countPerPage, page) =>
      dispatch(attemptGetExpertList(countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddPromotion));
