import React from "react";
import "./assets/css/fgadmin.css";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import apiMiddleWare from "./redux/apiMiddleWare";
import mainReducer from "./redux/reducers";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Routes from "./configs/routes";

const store = createStore(
  mainReducer,
  applyMiddleware(
    // logger,
    apiMiddleWare
    // logger
  )
);
export const history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default App;
