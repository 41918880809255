import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./../Success.css";
import { buttons } from "../../../configs/constants";
import { PLATFORM_LANGUAGES } from "./../Headquarters/CreateHeadquartersModal";
import { parseDataForRequestInHeadquarters } from "../../tradingPlatform/util";
import GeneralApi from "../../../api";

const API = new GeneralApi();

class UpdateHeadquartersModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getDefaultLanguagesParams(),
      lang: PLATFORM_LANGUAGES[0],
      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,
      langBtnIndex: 1,

      name: "",
      sysname: ""
    };
  }

  getDefaultLanguagesParams() {
    const languages = {};
    PLATFORM_LANGUAGES.forEach(lang => {
      languages[lang] = {
        name: ""
      };
    });

    return languages;
  }

  sendRequest = () => {
    const {
      responseStatus,
      isSuccessModalOpened,
      open,
      langBtnIndex,
      lang,
      ...mainData
    } = this.state;
    API.updateHeadQuarters(
      parseDataForRequestInHeadquarters(mainData),
      this.props.id
    )
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));
    this.setState({ isSuccessModalOpened: true });
  };
  toggleButtonRequest = () => {
    const {
      responseStatus,
      isSuccessModalOpened,
      open,
      langBtnIndex,
      lang,
      ...mainData
    } = this.state;

    API.updateHeadQuarters(
      parseDataForRequestInHeadquarters(mainData),
      this.props.id
    )
      .then(response => {
        response.text();
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));
  };

  successFunction = () => {
    PLATFORM_LANGUAGES.forEach(lang => {
      this.setState({
        [lang]: {
          ...this.state[lang],
          name: ""
        },
        isSuccessModalOpened: false
      });
      this.props.onHide();
    });
  };

  getDataForUpdate = () => {
    // alert()
    fetch(
      process.env.REACT_APP_API_URL +
        `/headquarter/${this.props.id}?lang=${this.state.lang}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({
          [this.state.lang]: {
            ...this.state[this.state.lang],
            name: data.name
          },
          sysname: data.sysname
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.getDataForUpdate();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.id !== prevProps.id || this.state.lang !== prevState.lang) {
      this.toggleButtonRequest();
      this.getDataForUpdate();
    }
  }

  render() {
    const { lang, isSuccessModalOpened } = this.state;

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
      >
        <Modal.Body>
          <div className="">
            {" "}
            {!isSuccessModalOpened ? (
              <div>
                <h4>Headquarters</h4>
                SYSNAME:
                <input
                  type="text"
                  value={this.state.sysname}
                  onChange={({ target }) =>
                    this.setState({ sysname: target.value })
                  }
                />
              </div>
            ) : null}
            {!isSuccessModalOpened
              ? buttons.map((item, index) => {
                  return (
                    <button
                      className={`btn ${
                        this.state.langBtnIndex === item.index
                          ? "activeBtn"
                          : "pasivBtn"
                      }`}
                      onClick={() => {
                        this.setState({
                          langBtnIndex: item.index,
                          lang: item.lang
                        });
                      }}
                    >
                      {item.lang}
                    </button>
                  );
                })
              : null}
          </div>
          {this.state.isSuccessModalOpened ? (
            <div>
              <section className="c-container">
                {this.state.responseStatus &&
                this.state.responseStatus > 400 ? (
                  <>
                    <div className="o-circle c-container__circle o-circle__sign--failure">
                      <div className="o-circle__sign" />
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() =>
                        this.setState({ isSuccessModalOpened: false })
                      }
                    >
                      try again
                    </button>
                  </>
                ) : (
                  <>
                    <div className="o-circle c-container__circle o-circle__sign--success">
                      <div className="o-circle__sign" />
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => this.successFunction()}
                    >
                      ok
                    </button>
                  </>
                )}
              </section>
            </div>
          ) : (
            <div
              style={{ height: "140px", alignItems: "center", display: "flex" }}
            >
              Name:
              <input
                type="text"
                placeholder="Headquarter..."
                value={this.state[lang].name}
                onChange={({ target }) =>
                  this.setState({
                    [lang]: { ...this.state[lang], name: target.value }
                  })
                }
              />
              <br />
            </div>
          )}
        </Modal.Body>
        {!this.state.isSuccessModalOpened ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Closeww
            </Button>
            <Button onClick={() => this.sendRequest()}>YES</Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

export default UpdateHeadquartersModal;
