import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./../Success.css";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { attemptGetPaymentOptionsList } from "../../../redux/actions/indexActions";
import { connect } from "react-redux";
import UpdatePaymentsModal from "./UpdatePaymentsModal";
import CreatePaymentsModal from "./CreatePaymentsModal";
import DeletePaymentsModal from "./DeletePaymentsModal";
class PaymentOptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page: 1,
      isSuccessModalOpened: false,
      ID: null,

      isShowDeleteBlock: false,
      isShowEditBlock: false,
      isShowCreateBlock: false,
      isSwEditBlock: false
    };
  }

  close = () => {
    this.setState({
      isShowDeleteBlock: false,
      isSuccessModalOpened: false
    });
  };

  componentDidMount() {
    const { page } = this.state;
    const { language } = this.props;
    this.props.attemptGetPaymentOptionsList(language, 4, page);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetPaymentOptionsList(
        this.props.language,
        4,
        pageNumber
      );
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.countPerPage !== prevState.countPerPage ||
      this.state.page !== prevState.page
    ) {
      this.props.attemptGetPaymentOptionsList(
        this.props.language,
        4,
        this.state.page
      );
    }
  }

  deleteArticle = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/payment/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetPaymentOptionsList(this.props.language, 5, 1);
        this.setState({ isSuccessModalOpened: true, activePage: 1 });
      })
      .catch(err => console.log(err));
    this.setState({ isShowEditBlock: false });
  };

  render() {
    const { paymentsList } = this.props;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
      >
        <Modal.Body>
          {!this.state.isShowEditBlock ? <h4>Payment Options</h4> : null}

          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {paymentsList &&
                  paymentsList.list.length &&
                  paymentsList.list.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                        <td>{item.name}</td>
                        <td>
                          <div
                            onClick={() => {
                              this.setState({
                                isSwEditBlock: true,
                                ID: item.id
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                        </td>
                        <td>
                          <div
                            onClick={() =>
                              this.setState({
                                isShowDeleteBlock: true,
                                ID: item.id
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {paymentsList && paymentsList.list.length > 0 ? (
              <div>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={4}
                  totalItemsCount={paymentsList.count}
                  pageRangeDisplayed={4}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>
            ) : null}
            <UpdatePaymentsModal
              show={this.state.isSwEditBlock}
              onHide={() => {
                this.setState({ isSwEditBlock: false });
              }}
              id={this.state.ID}
              isSuccessModalOpened={this.state.isSuccessModalOpened}
            />
            <CreatePaymentsModal
              show={this.state.isShowCreateBlock}
              onHide={() => {
                this.setState({ isShowCreateBlock: false });
              }}
              isSuccessModalOpened={this.state.isSuccessModalOpened}
            />
            <DeletePaymentsModal
              show={this.state.isShowDeleteBlock}
              onsend={() => this.deleteArticle(this.state.ID)}
              onHide={() => {
                this.setState({ isShowDeleteBlock: false });
              }}
              isSuccessModalOpened={this.state.isSuccessModalOpened}
              closemodal={this.close}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!this.state.isShowEditBlock ? (
            <>
              <Button onClick={this.props.onHide}>Close</Button>
              <Button
                onClick={() => this.setState({ isShowCreateBlock: true })}
              >
                Add new
              </Button>
            </>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    paymentsList: store.paymentsList,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetPaymentOptionsList: (lang, countPerPage, page) =>
      dispatch(attemptGetPaymentOptionsList(lang, countPerPage, page))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentOptionModal));
