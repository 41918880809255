import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isUserLoggedIn, isUserLoggedOut } from "../redux/actions/indexActions";
import { connect } from "react-redux";
import { FaUserAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

import Dropdown from "bootstrap-4-react";
import { LOGIN } from "../configs/constants";

class AdminAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: ""
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + `/admin/user`, {
      method: "get",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email
        });
      })
      .catch(err => {
        this.props.userLogOut();
        this.props.history.push(LOGIN);
      });
  }

  render() {
    return (
      <div>
        {/*<Dropdown>*/}
        {/*    <Dropdown.Toggle variant="success" id="dropdown-basic">*/}
        {/*        Dropdown Button*/}
        {/*    </Dropdown.Toggle>*/}

        {/*    <Dropdown.Menu>*/}
        {/*        <Dropdown.Item href="#/action-1">{`first name---- ${this.state.firstName}` }</Dropdown.Item>*/}
        {/*        <Dropdown.Item href="#/action-2">{`last name---- ${this.state.lastName}`}</Dropdown.Item>*/}
        {/*        <Dropdown.Item href="#/action-3" >{`email--- ${this.state.email}`}</Dropdown.Item>*/}
        {/*        <Dropdown.Item href="#/action-3" onClick={this.props.attemptLogOut}>LogOut</Dropdown.Item>*/}
        {/*    </Dropdown.Menu>*/}
        {/*</Dropdown>*/}
        <div className="dropdown">
          <button
            className="btn btn-primary rounded-circle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FaUserAlt />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
            <button className="dropdown-item" type="button">
              {" "}
              {this.state.firstName}
            </button>
            <button className="dropdown-item" type="button">
              {this.state.lastName}
            </button>
            <button className="dropdown-item" type="button">
              {this.state.email}
            </button>
            <button className="dropdown-item" type="button">
              <button
                className="btn btn-danger"
                onClick={this.props.attemptLogOut}
              >
                Log out <FiLogOut />{" "}
              </button>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    isLoggedIn: store.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(isUserLoggedIn()),
    userLogOut: () => dispatch(isUserLoggedOut())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminAvatar));
