import {
  ATTEMPT_GET_ARTICLE_LIST,
  getArticleListSuccess,
  getArticleListFailed,
  ATTEMPT_GET_EXPERT_LIST,
  getExpertListSuccess,
  getExpertListFailed,
  ATTEMPT_GET_USERS_LIST,
  getUsersListSuccess,
  getUsersListFailed,
  ATTEMPT_GET_PROMOTIONS_LIST,
  getPromotionsListSuccess,
  getPromotionsListFailed,
  ATTEMPT_GET_BROKER_LIST,
  getExpertBrokersSuccess,
  getBrokersListFailed,
  ATTEMPT_GET_TRADING_PLATFORMS_LIST,
  getTradingPlatformsListSuccess,
  getTradingPlatformsListFailed,
  ATTEMPT_GET_HEADQUARTERS_LIST,
  getHeadquartersListSuccess,
  getHeadquartersListFailed,
  ATTEMPT_GET_PAYMENT_OPTIONS_LIST,
  getPaymentOptionsListSuccess,
  getPaymentOptionsListFailed,
  GET_HEADQUARTERS_FULL_LIST,
  getHeadquartersFullListSuccess,
  getHeadquartersFullListFailed,
  GET_PAYMENT_OPTIONS_FULL_LIST,
  getPaymentOptionsFullListSuccess,
  getPaymentOptionsFullListFailed
} from "./actions/indexActions";

import GeneralApi from "../api";
const Api = new GeneralApi();

const apiMiddleWare = store => next => action => {
  const { type, payload } = action;
  switch (type) {
    case ATTEMPT_GET_ARTICLE_LIST:
      Api.getArticleList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getArticleListSuccess(res));
          } else {
            next(getArticleListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_USERS_LIST:
      Api.getUsersList(payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getUsersListSuccess(res));
          } else {
            next(getUsersListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_PROMOTIONS_LIST:
      Api.getPromotionsList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getPromotionsListSuccess(res));
          } else {
            next(getPromotionsListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_TRADING_PLATFORMS_LIST:
      Api.getTradingPlatformsList(
        payload.lang,
        payload.countPerPage,
        payload.page
      )
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getTradingPlatformsListSuccess(res));
          } else {
            next(getTradingPlatformsListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_HEADQUARTERS_LIST:
      Api.getHeadquartersList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getHeadquartersListSuccess(res));
          } else {
            next(getHeadquartersListFailed(res));
          }
        });
      break;
    case GET_HEADQUARTERS_FULL_LIST:
      Api.getHeadquartersFullList()
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getHeadquartersFullListSuccess(res));
          } else {
            next(getHeadquartersFullListFailed(res));
          }
        });
      break;
    case GET_PAYMENT_OPTIONS_FULL_LIST:
      Api.getPaymentOptionsFullList()
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getPaymentOptionsFullListSuccess(res));
          } else {
            next(getPaymentOptionsFullListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_PAYMENT_OPTIONS_LIST:
      Api.getPaymentOptionsList(
        payload.lang,
        payload.countPerPage,
        payload.page
      )
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getPaymentOptionsListSuccess(res));
          } else {
            next(getPaymentOptionsListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_EXPERT_LIST:
      Api.getExpertList(payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getExpertListSuccess(res));
          } else {
            next(getExpertListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_BROKER_LIST:
      Api.getBrokersList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getExpertBrokersSuccess(res));
          } else {
            next(getBrokersListFailed(res));
          }
        });
      break;

    default:
      next(action);
  }
};

export default apiMiddleWare;
