import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import {PROMOTION_UPDATE_PAGE, ADD_PROMOTIONS, LOGIN} from "../../configs/constants";
import {
  attemptGetPromotionsList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSuccessModalOpened: false,
      ID: null,
      activePage: 1,
      page: 1,
      item: "promotion",
      countPerPage: 10
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.props.attemptGetPromotionsList(this.props.language, this.state.countPerPage, pageNumber);
    });
  }

  componentDidMount() {
    this.props.toggleMenuTab(6);
    this.props.attemptGetPromotionsList(
      this.props.language,
        this.state.countPerPage,
      this.state.activePage
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.countPerPage !== prevState.countPerPage) {
      this.props.attemptGetPromotionsList(
        this.props.language,
          this.state.countPerPage,
        this.state.page
      );
    }
  }

  close = () => {
    this.setState({
      open: false,
      isSuccessModalOpened: false
    });
  };

  deletePromotions = id => {
    fetch(process.env.REACT_APP_API_URL + `/admin/promotion/` + id, {
      method: "delete",
      headers: {
        Authorization: `token ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        this.props.attemptGetPromotionsList("en", this.state.countPerPage, 1);
        this.setState({ isSuccessModalOpened: true, activePage: 1, responseStatus: res.status });
      })
        .catch(err => {
          this.props.userLogOut();
          this.props.history.push(LOGIN);
        });
  };

  render() {
    const { promotions } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Promotions</title>
        </Helmet>
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {promotions &&
                promotions.list &&
                promotions.list.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index+1+(this.state.activePage-1)*this.state.countPerPage}</th>
                      <td>{item.sysname}</td>
                      <td>
                        <Link to={PROMOTION_UPDATE_PAGE(item.id)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                      <td>
                        {/*<div onClick={() => this.deleteExpert(item.expertId)}>*/}
                        <div
                          onClick={() =>
                            this.setState({ open: true, ID: item.id })
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {promotions && promotions.count > this.state.countPerPage ? (
            <div className="mb-4">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.countPerPage}
                totalItemsCount={promotions.count}
                pageRangeDisplayed={this.state.countPerPage}
                onChange={e => this.handlePageChange(e)}
              />
            </div>
          ) : null}

          <button className="btn btn-light mb-5">
            <Link className={"text-decoration-none"} to={ADD_PROMOTIONS}>
              <FontAwesomeIcon icon={faAddressCard} /> Add new promotion
            </Link>
          </button>
          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={() => this.deletePromotions(this.state.ID)}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    promotions: store.promotions
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetPromotionsList: (lang, countPerPage, page) =>
      dispatch(attemptGetPromotionsList(lang, countPerPage, page)),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Promotions));
