import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuNav from "./components/menuNav/MenuNav";
import MainPage from "./components/mainPage";
import { isUserLoggedIn } from "./redux/actions/indexActions";
import interceptor from "./configs/interceptor";
import { LOGIN } from "./configs/constants";

class MainLayout extends React.Component {
  componentDidMount() {
    interceptor();
    if (localStorage.getItem("accessToken")) {
      this.props.userLogin();
    } else this.props.history.push(LOGIN);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.history.location.pathname.includes("/login") &&
      !localStorage.getItem("accessToken")
    ) {
      this.props.history.push(LOGIN);
    }
  }

  render() {
    return (
      <div>
        <div className="lang">
          <MainPage />
          {this.props.isLoggedIn ? <MenuNav /> : null}
          <div className="profile_leyout_div content">
            {this.props.children}
          </div>
          {/*<Footer />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    isLoggedIn: store.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(isUserLoggedIn())
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainLayout));
