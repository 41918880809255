import React from "react";
import MyVerticallyCenteredModal from "../popup/MyVerticallyCenteredModal";
import { Button } from "react-bootstrap";
import {
  attemptGetExpertList,
  toggleMenuTab
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import {
  ARTICLE_UPDATE_PAGE,
  ARTICLES,
  buttons
} from "../../configs/constants";
import { Helmet } from "react-helmet";
class UpdateArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: "",
      slug: "",
      en: {
        title: "",
        description: "",
        text: ""
      },
      ru: {
        title: "",
        description: "",
        text: ""
      },
      es: {
        title: "",
        description: "",
        text: ""
      },
      pt: {
        title: "",
        description: "",
        text: ""
      },
      ar: {
        title: "",
        description: "",
        text: ""
      },
      fa: {
        title: "",
        description: "",
        text: ""
      },
      tr: {
        title: "",
        description: "",
        text: ""
      },
      vi: {
        title: "",
        description: "",
        text: ""
      },
      ja: {
        title: "",
        description: "",
        text: ""
      },
      ID: {
        title: "",
        description: "",
        text: ""
      },
      cs: {
        title: "",
        description: "",
        text: ""
      },
      hi: {
        title: "",
        description: "",
        text: ""
      },
      de: {
        title: "",
        description: "",
        text: ""
      },
      it: {
        title: "",
        description: "",
        text: ""
      },
      ms: {
        title: "",
        description: "",
        text: ""
      },
      zh: {
        title: "",
        description: "",
        text: ""
      },
      zh_cn: {
        title: "",
        description: "",
        text: ""
      },

      open: false,
      isSuccessModalOpened: false,

      item: "Article",
      expertId: 0,

      openTransFields: true,

      langBtnIndex: 1,
      lang: "en",
      responseStatus: null
    };
  }

  componentDidMount() {
    this.props.attemptGetExpertList();
    Object.keys(this.state).forEach(key => {
      if (typeof this.state[key] == "object") {
        fetch(
          process.env.REACT_APP_API_URL +
            `/articles/details?lang=${key}&slug=${this.props.match.params.slug}`,
          {
            method: "get",
            headers: {
              Authorization: `token ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
          .then(res => res.json())
          .then(data => {
            this.setState({
              [key]: {
                title: data.title,
                description: data.description,
                text: data.content
              },
              slug: this.props.match.params.slug,
              id: data.id,
              expertId: data.expertId
            });
          })
          .catch(err => console.log(err));
      }
    });
    this.props.toggleMenuTab(2);
  }

  close = () => {
    this.setState(
      {
        title: "",
        description: "",
        picture: "",
        slug: "",
        expertId: 0,
        open: false,
        isSuccessModalOpened: false
      },
      () => this.props.history.push(ARTICLES)
    );
  };

  sendRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    const urlencoded = new URLSearchParams();
    urlencoded.append("slug", this.state.slug);
    urlencoded.append("expertId", this.state.expertId);
    urlencoded.append("translations[en][title]", this.state.en.title);
    urlencoded.append(
      "translations[en][description]",
      this.state.en.description
    );
    urlencoded.append("translations[en][text]", this.state.en.text);
    urlencoded.append("translations[ru][title]", this.state.ru.title);
    urlencoded.append(
      "translations[ru][description]",
      this.state.ru.description
    );
    urlencoded.append("translations[ru][text]", this.state.ru.text);

    ///////////////////////////////////es////////////////////////////////////////
    urlencoded.append("translations[es][title]", this.state.es.title);
    urlencoded.append(
      "translations[es][description]",
      this.state.es.description
    );
    urlencoded.append("translations[es][text]", this.state.es.text);
    ///////////////////////////////////pt////////////////////////////////////////
    urlencoded.append("translations[pt][title]", this.state.pt.title);
    urlencoded.append(
      "translations[pt][description]",
      this.state.pt.description
    );
    urlencoded.append("translations[pt][text]", this.state.pt.text);
    ///////////////////////////////////ar////////////////////////////////////////
    urlencoded.append("translations[ar][title]", this.state.ar.title);
    urlencoded.append(
      "translations[ar][description]",
      this.state.ar.description
    );
    urlencoded.append("translations[ar][text]", this.state.ar.text);
    ///////////////////////////////////fa////////////////////////////////////////
    urlencoded.append("translations[fa][title]", this.state.fa.title);
    urlencoded.append(
      "translations[fa][description]",
      this.state.fa.description
    );
    urlencoded.append("translations[fa][text]", this.state.fa.text);
    ///////////////////////////////////tr////////////////////////////////////////
    urlencoded.append("translations[tr][title]", this.state.tr.title);
    urlencoded.append(
      "translations[tr][description]",
      this.state.tr.description
    );
    urlencoded.append("translations[tr][text]", this.state.tr.text);
    ///////////////////////////////////vi////////////////////////////////////////
    urlencoded.append("translations[vi][title]", this.state.vi.title);
    urlencoded.append(
      "translations[vi][description]",
      this.state.vi.description
    );
    urlencoded.append("translations[vi][text]", this.state.vi.text);
    ///////////////////////////////////ja////////////////////////////////////////
    urlencoded.append("translations[ja][title]", this.state.ja.title);
    urlencoded.append(
      "translations[ja][description]",
      this.state.ja.description
    );
    urlencoded.append("translations[ja][text]", this.state.ja.text);
    ///////////////////////////////////ID////////////////////////////////////////
    urlencoded.append("translations[ID][title]", this.state.ID.title);
    urlencoded.append(
      "translations[ID][description]",
      this.state.ID.description
    );
    urlencoded.append("translations[ID][text]", this.state.ID.text);
    ///////////////////////////////////cs////////////////////////////////////////
    urlencoded.append("translations[cs][title]", this.state.cs.title);
    urlencoded.append(
      "translations[cs][description]",
      this.state.cs.description
    );
    urlencoded.append("translations[cs][text]", this.state.cs.text);
    ///////////////////////////////////hi////////////////////////////////////////
    urlencoded.append("translations[hi][title]", this.state.hi.title);
    urlencoded.append(
      "translations[hi][description]",
      this.state.hi.description
    );
    urlencoded.append("translations[hi][text]", this.state.hi.text);
    ///////////////////////////////////de////////////////////////////////////////
    urlencoded.append("translations[de][title]", this.state.de.title);
    urlencoded.append(
      "translations[de][description]",
      this.state.de.description
    );
    urlencoded.append("translations[de][text]", this.state.de.text);
    ///////////////////////////////////it////////////////////////////////////////
    urlencoded.append("translations[it][title]", this.state.it.title);
    urlencoded.append(
      "translations[it][description]",
      this.state.it.description
    );
    urlencoded.append("translations[it][text]", this.state.it.text);
    ///////////////////////////////////ms////////////////////////////////////////
    urlencoded.append("translations[ms][title]", this.state.ms.title);
    urlencoded.append(
      "translations[ms][description]",
      this.state.ms.description
    );
    urlencoded.append("translations[ms][text]", this.state.ms.text);
    ///////////////////////////////////zh////////////////////////////////////////
    urlencoded.append("translations[zh][title]", this.state.zh.title);
    urlencoded.append(
      "translations[zh][description]",
      this.state.zh.description
    );
    urlencoded.append("translations[zh][text]", this.state.zh.text);
    ///////////////////////////////////zh_cn////////////////////////////////////////
    urlencoded.append("translations[zh_cn][title]", this.state.zh_cn.title);
    urlencoded.append(
      "translations[zh_cn][description]",
      this.state.zh_cn.description
    );
    urlencoded.append("translations[zh_cn][text]", this.state.zh_cn.text);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(
      process.env.REACT_APP_API_URL + `/admin/article/${this.state.id}`,
      requestOptions
    )
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  render() {
    const articleExpert =
      this.props.experts &&
      this.props.experts.list.length &&
      this.props.experts.list.filter((item, index) => {
        return item.expertId === this.state.expertId;
      });

    const { lang } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            ForexGrad adminpanel | Articles | Edit "{this.state.slug}" article
          </title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              {buttons.map((item, index) => {
                return (
                  <button
                    className={`btn ${
                      this.state.langBtnIndex === item.index
                        ? "activeBtn"
                        : "pasivBtn"
                    }`}
                    onClick={() => {
                      this.setState({
                        langBtnIndex: item.index,
                        lang: item.lang
                      });
                    }}
                  >
                    {item.lang}
                  </button>
                );
              })}
            </div>

            <div className="col-12 rating_info mb-3">
              <div className="d-flex justify-content-between mb-2">
                <span>Slug</span>
                <span className="d-flex">
                  <h2>{this.state.slug}</h2>
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Expert</span>
                <span className="d-flex">
                  {this.props.experts && (
                    <h2>
                      {articleExpert &&
                        articleExpert.length &&
                        articleExpert[0].lastName}
                    </h2>
                  )}
                </span>
              </div>
            </div>

            <div className="col-12 mb-2">
              <h2>
                <strong>Article title / </strong>
                <span>
                  <input
                    className="p-1"
                    type="text"
                    placeholder="Article title"
                    value={this.state[lang].title}
                    onChange={e => {
                      this.setState({
                        [lang]: { ...this.state[lang], title: e.target.value }
                      });
                    }}
                  />
                </span>
              </h2>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-6 d-flex mb-4">
                  <textarea
                    className="w-100"
                    name="desc"
                    id=""
                    cols="150"
                    rows="3"
                    placeholder="Broker short description"
                    value={this.state[lang].description}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          description: e.target.value
                        }
                      });
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 d-flex mb-4">
                  <textarea
                    className="w-100"
                    name="desc"
                    id=""
                    cols="150"
                    rows="3"
                    placeholder="Broker text"
                    value={this.state[lang].text}
                    onChange={e => {
                      this.setState({
                        [lang]: { ...this.state[lang], text: e.target.value }
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                UPDATE ARTICLE
              </Button>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    experts: store.experts
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetExpertList: () => dispatch(attemptGetExpertList()),
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateArticle);
