import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./../Success.css";
import { buttons } from "../../../configs/constants";
import { PLATFORM_LANGUAGES } from "./../Headquarters/CreateHeadquartersModal";
import { parseDataForRequestInPayments } from "../../tradingPlatform/util";
import GeneralApi from "../../../api";

const API = new GeneralApi();

class UpdatePaymentsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getDefaultLanguagesParams(),
      lang: PLATFORM_LANGUAGES[0],
      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,
      langBtnIndex: 1,

      name: "",
      sysname: ""
    };
  }

  getDefaultLanguagesParams() {
    const languages = {};
    PLATFORM_LANGUAGES.forEach(lang => {
      languages[lang] = {
        name: ""
      };
    });

    return languages;
  }

  sendRequest = () => {
    const {
      responseStatus,
      isSuccessModalOpened,
      open,
      langBtnIndex,
      lang,
      ...mainData
    } = this.state;

    console.log(mainData, "mainData");
    API.updatePayments(parseDataForRequestInPayments(mainData), this.props.id)
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      .then(result => console.log(result))
      .catch(error => console.log("error", error));
    this.setState({ isSuccessModalOpened: true });
  };
  toggleButtonRequest = () => {
    const {
      responseStatus,
      isSuccessModalOpened,
      open,
      langBtnIndex,
      lang,
      ...mainData
    } = this.state;

    console.log(mainData, "mainData");
    API.updatePayments(parseDataForRequestInPayments(mainData), this.props.id)
      .then(response => {
        response.text();
      })
      .then(result => console.log(result))
      .catch(error => console.log("error", error));
  };

  successFunction = () => {
    PLATFORM_LANGUAGES.forEach(lang => {
      this.setState({
        [lang]: {
          ...this.state[lang],
          name: ""
        },
        isSuccessModalOpened: false
      });
      this.props.onHide();
    });
  };

  getDataForUpdate = () => {
    // alert()
    fetch(
      process.env.REACT_APP_API_URL +
        `/payment/${this.props.id}?lang=${this.state.lang}`,
      {
        method: "get",
        headers: {
          Authorization: `token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        console.log(data, "D_A_T_A");
        this.setState({
          [this.state.lang]: {
            ...this.state[this.state.lang],
            name: data.name
          },
          sysname: data.sysname
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.getDataForUpdate();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.id !== prevProps.id || this.state.lang !== prevState.lang) {
      this.toggleButtonRequest();
      this.getDataForUpdate();
    }
  }

  render() {
    const { lang, isSuccessModalOpened } = this.state;

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
      >
        <Modal.Body>
          <div className="">
            {" "}
            {!isSuccessModalOpened ? (
              <div>
                <h4>Headquarters</h4>
                SYSNAME:
                <input
                  type="text"
                  value={this.state.sysname}
                  onChange={({ target }) =>
                    this.setState({ sysname: target.value })
                  }
                />
              </div>
            ) : null}
            {!isSuccessModalOpened
              ? buttons.map((item, index) => {
                  return (
                    <button
                      className={`btn ${
                        this.state.langBtnIndex === item.index
                          ? "activeBtn"
                          : "pasivBtn"
                      }`}
                      onClick={() => {
                        this.setState({
                          langBtnIndex: item.index,
                          lang: item.lang
                        });
                      }}
                    >
                      {item.lang}
                    </button>
                  );
                })
              : null}
          </div>
          {this.state.isSuccessModalOpened ? (
            <div>
              <section className="c-container">
                {this.state.responseStatus &&
                this.state.responseStatus > 400 ? (
                  <>
                    <div className="o-circle c-container__circle o-circle__sign--failure">
                      <div className="o-circle__sign" />
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() =>
                        this.setState({ isSuccessModalOpened: false })
                      }
                    >
                      try again
                    </button>
                  </>
                ) : (
                  <>
                    <div className="o-circle c-container__circle o-circle__sign--success">
                      <div className="o-circle__sign" />
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => this.successFunction()}
                    >
                      ok
                    </button>
                  </>
                )}
              </section>
            </div>
          ) : (
            <div
              style={{ height: "140px", alignItems: "center", display: "flex" }}
            >
              Name:
              <input
                type="text"
                placeholder="Headquarter..."
                value={this.state[lang].name}
                onChange={({ target }) =>
                  this.setState({
                    [lang]: { ...this.state[lang], name: target.value }
                  })
                }
              />
              <br />
            </div>
          )}
        </Modal.Body>
        {!this.state.isSuccessModalOpened ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
              Closeww
            </Button>
            <Button onClick={() => this.sendRequest()}>YES</Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

export default UpdatePaymentsModal;

// logo: "http://127.0.0.1:2083/images/brokers/xm.svg"
// sysname: "XM"
// description: "XM was founded in 2009 with headquarter in Cyprus regulated by CySEC, FCA, ASIC, IFSC. Broker offers accounts for trading with wide range of instruments with low fees. XM was awarded as Best FX Service Provider, Best Customer Service Global 2019 and many more. Traders can get promotions and participate in different bonus programs. "
// averageRate: "4.0"
// founded: 2009
// brokerType: "Market Maker
// ↵"
// maxLeverage: "1:500
// ↵"
// timeZone: "GMT+2 (winter time), GMT+3 (summer time)
// ↵"
// usTradersAccepted: 0
// currencyPairsAmount: 57
// currencies: (8) ["USD", "EUR", "GBP", "CHF", "AUD", "HUF", "PLN", "JPY"]
// headquarters: ["Cyprus"]
// paymentOptions: (5) ["Card Payment", "Skrill", "WebMoney", "Neteller", "SticPay"]
// demoPlatform: (2) ["MT4", "MT5"]
// regulatedBy: (4) ["CySEC (Cyprus)", "FCA (UK)", " ASIC (Australia)", " IFSC (Belize)"]
// tradingPlatforms: (2) ["mt4", "mt5"]
// tradingInstruments: (7) ["Forex", "Precious Metals", "Stock CFDs", "Commodities", "Equity Indices", "Energies", "CryptoCurrencies"]
// customerSupportVia: (3) ["Live Chat", "Email", "Phone"]
// spreadType: ["Floating"]
// promotion: (2) ["free_vps_services", "zero_fees_deposits_withdrawls"]
// advantages: Array(10)
// 0: {bonusProgram: "1"}
// 1: {tradingContests: "0"}
// 2: {freeEducation: "1"}
// 3: {personalManager: "1"}
// 4: {islamicAccountsAvailable: "1"}
// 5: {tradingByPhone: "1"}
// 6: {affiliateProgram: "1"}
// 7: {freeAnalytics: "1"}
// 8: {wideChoiceInstruments: "0"}
// 9: {freeMarginInterest: "0"}
// length: 10
// __proto__: Array(0)
// websiteLanguages: (23) ["en", "de", "it", "vn", "ms", "ar", "fr", "es", "ru", "cs", "id", "pt", "zh_cn", "zh_tw", "pl", "ko", "th", "el", "hu", "sv", "fil", "nl", "bn"]
// reviewCount: 0
