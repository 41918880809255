import React from "react";
import { Button } from "react-bootstrap";
import {
  ADMIN_PAYMENT,
  BROKER_HEADQUARTERS,
  buttons
} from "./../../../configs/constants";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import MyVerticallyCenteredModal from "../../popup/MyVerticallyCenteredModal";
import { PAYMENT_OPTIONS } from "../../../configs/constants";
import { toggleMenuTab } from "../../../redux/actions/indexActions";
import { connect } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
class AddPaymentOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sysname: "",

      open: false,
      isSuccessModalOpened: false,
      responseStatus: null,
      item: "Expert",
      en: {
        name: ""
      },
      ru: {
        name: ""
      },
      es: {
        name: ""
      },
      pt: {
        name: ""
      },
      ar: {
        name: ""
      },
      fa: {
        name: ""
      },
      tr: {
        name: ""
      },
      vi: {
        name: ""
      },
      ja: {
        name: ""
      },
      ID: {
        name: ""
      },
      cs: {
        name: ""
      },
      hi: {
        name: ""
      },
      de: {
        name: ""
      },
      it: {
        name: ""
      },
      ms: {
        name: ""
      },
      zh: {
        name: ""
      },
      zh_cn: {
        name: ""
      },

      langBtnIndex: 1,
      lang: "en",
      logo: ""
    };
  }

  close = () => {
    this.setState(
      {
        picture: "",
        firstName: "",
        lastName: "",

        open: false,
        isSuccessModalOpened: false
      },
      () => this.props.history.push(BROKER_HEADQUARTERS)
    );
  };

  sendRequest = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `token ${localStorage.getItem("accessToken")}`
    );

    const urlencoded = new URLSearchParams();
    urlencoded.append("sysname", this.state.sysname);
    //////////////////////////////////////// en /////////////////////////////////////
    urlencoded.append("translations[en][name]", this.state.en.name);
    //////////////////////////////////////// ru /////////////////////////////////////
    urlencoded.append("translations[ru][name]", this.state.ru.name);
    //////////////////////////////////////// es /////////////////////////////////////
    urlencoded.append("translations[es][name]", this.state.es.name);
    //////////////////////////////////////// pt /////////////////////////////////////
    urlencoded.append("translations[pt][name]", this.state.pt.name);
    //////////////////////////////////////// ar /////////////////////////////////////
    urlencoded.append("translations[ar][name]", this.state.ar.name);
    //////////////////////////////////////// fa /////////////////////////////////////
    urlencoded.append("translations[fa][name]", this.state.fa.name);
    //////////////////////////////////////// tr /////////////////////////////////////
    urlencoded.append("translations[tr][name]", this.state.tr.name);
    //////////////////////////////////////// vi /////////////////////////////////////
    urlencoded.append("translations[vi][name]", this.state.vi.name);
    //////////////////////////////////////// ja /////////////////////////////////////
    urlencoded.append("translations[ja][name]", this.state.ja.name);
    //////////////////////////////////////// ID /////////////////////////////////////
    urlencoded.append("translations[ID][name]", this.state.ID.name);
    //////////////////////////////////////// cs /////////////////////////////////////
    urlencoded.append("translations[cs][name]", this.state.cs.name);
    //////////////////////////////////////// hi /////////////////////////////////////
    urlencoded.append("translations[hi][name]", this.state.hi.name);
    //////////////////////////////////////// de /////////////////////////////////////
    urlencoded.append("translations[de][name]", this.state.de.name);
    //////////////////////////////////////// it /////////////////////////////////////
    urlencoded.append("translations[it][name]", this.state.it.name);
    //////////////////////////////////////// ms /////////////////////////////////////
    urlencoded.append("translations[ms][name]", this.state.ms.name);
    //////////////////////////////////////// zh /////////////////////////////////////
    urlencoded.append("translations[zh][name]", this.state.zh.name);
    //////////////////////////////////////// zh_cn /////////////////////////////////////
    urlencoded.append("translations[zh_cn][name]", this.state.zh_cn.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_API_URL + `${ADMIN_PAYMENT}`, requestOptions)
      .then(response => {
        response.text();
        this.setState({ responseStatus: response.status });
      })
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));

    this.setState({ isSuccessModalOpened: true });
  };

  componentDidMount() {
    this.props.toggleMenuTab(1);
  }

  render() {
    const { lang } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ForexGrad adminpanel | Experts | Create new expert</title>
        </Helmet>
        <div className="container forex_company">
          <div className="row">
            <div className="col-12">
              <h2>Payments Option</h2>
              SYSNAME:{" "}
              <input
                type="text"
                onChange={({ target }) =>
                  this.setState({ sysname: target.value })
                }
              />
            </div>
            <div className="col-12">
              {buttons.map((item, index) => {
                return (
                  <button
                    className={`btn ${
                      this.state.langBtnIndex === item.index
                        ? "activeBtn"
                        : "pasivBtn"
                    }`}
                    onClick={() => {
                      this.setState({
                        langBtnIndex: item.index,
                        lang: item.lang
                      });
                    }}
                  >
                    {item.lang}
                  </button>
                );
              })}
            </div>

            <div className="col-12 rating_info mb-4">
              <div className="d-flex justify-content-between mb-2">
                <span>Payment method name</span>
                <span className="d-flex">
                  <input
                    type="text"
                    value={this.state[lang].name}
                    onChange={e => {
                      this.setState({
                        [lang]: {
                          ...this.state[lang],
                          name: e.target.value
                        }
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

          <MyVerticallyCenteredModal
            show={this.state.open}
            onHide={() => {
              this.setState({ open: false });
            }}
            onsend={this.sendRequest}
            isSuccessModalOpened={this.state.isSuccessModalOpened}
            closemodal={this.close}
            item={this.state.item}
            responseStatus={this.state.responseStatus}
          />
          <div className="row">
            <div className="col-6">
              <Button
                  variant="primary"
                  size="lg"
                  block
                  disabled={!this.state.en.name || !this.state.en.name}
                  onClick={() => {
                    this.setState({ open: true });
                  }}
              >
                Save payment method
              </Button>
            </div>
            <div className="col-6">
              <Button
                  variant="primary"
                  size="lg"
                  className="btn btn-dark w-100"
                  onClick={() => {
                    this.props.history.push(PAYMENT_OPTIONS);
                  }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                {` Back`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    currentBrokerSysname: store.currentBrokerSysname
  };
};
function mapDispatchToProps(dispatch) {
  return {
    toggleMenuTab: index => dispatch(toggleMenuTab(index))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddPaymentOption));
